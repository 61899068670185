<template>
  <div class="realname">
    <div class="realname-form">
      <el-form ref="form" :model="form" :rules="rules" label-width="130px">
        <el-form-item label="认证角色:" prop="applyRole">
          <el-checkbox-group v-model="form.applyRole" size="small">
            <div class="group-list">
              <el-checkbox v-for="(item, $index) of roleEnum" :key="$index" :label="$index" border>{{item}}</el-checkbox>
            </div>
            <!-- <el-checkbox label="生产方" border></el-checkbox>
            <el-checkbox label="版权方" border></el-checkbox>
            <el-checkbox label="发行方" border></el-checkbox> -->
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="简介:" prop="info">
          <el-input v-model="form.info" maxlength="255" show-word-limit :rows="3" clearable class="input-large" type="textarea" placeholder="请输入简介，拖动右下角可增加文本区域"></el-input>
        </el-form-item>
        <el-form-item label="上传代表作品:" prop="fileIds">
          <Upload @onChange="workChange">
            <div v-if="!workObj.path" class="upload-work">
              <i class="el-icon-plus"></i>
            </div>
            <el-image v-if="workObj.path" class="work-img" :src="workObj.path" fit="cover"></el-image>
          </Upload>
          <!-- <el-input clearable class="input-long" v-model="form.name"></el-input> -->
        </el-form-item>
        <el-form-item label="作品名称:" prop="workTitle">
          <el-input clearable class="input-long" v-model="form.workTitle"></el-input>
        </el-form-item>
        <el-form-item label="作品描述:" prop="description">
          <el-input v-model="form.description" maxlength="255" show-word-limit :rows="3" clearable class="input-large" type="textarea" placeholder="请输入作品描述，拖动右下角可增加文本区域"></el-input>
        </el-form-item>
        <el-form-item label="手机号:" prop="phone">
          <el-input clearable class="input-middle" v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email">
          <el-input clearable class="input-long" v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="验证码:" prop="code">
          <el-input clearable class="input-small mrl" v-model="form.code"></el-input>
          <ImgCode @onChange="imgCodeChange" ref="imgcode"/>
          <el-button @click="handleChangeCode" type="text" size="mini">换一张</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="dialog-btn">
      <el-button @click="handleCancel">取消</el-button>
      <el-button @click="handleSubmit" type="primary">提交申请</el-button>
    </div>
  </div>
</template>

<script>
import { addAccount } from '@/api/index'
import { ROLE_ENUM } from '@/config/index'
import ImgCode from '@/components/Vcode/ImgCode.vue'
import Upload from '@/components/Upload/index.vue'
export default {
  name: "RealNameAuth",
  data() {
    return {
      roleEnum: ROLE_ENUM,
      form: {
        applyRole: [],
        info: '',
        workTitle: '',
        description: '',
        phone: '',
        email: '',
        code: '',
        uuid: '',
        fileIds: '',
      },
      workObj: {},
      rules: {
        applyRole: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        info: [{ required: true, message: '请填写简介', trigger: 'blur' }],
        workTitle: [{ required: true, message: '请填写作品名称', trigger: 'blur' }],
        description: [{ required: true, message: '请填写作品描述', trigger: 'blur' }],
        phone: [
          { required: true, message: '请填写手机号', trigger: 'blur' },
          { pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/, message: '手机号格式不正确' }
        ],
        email: [
          { required: true, message: '请填写邮箱', trigger: 'blur' },
          { pattern: /^\w+@[a-z0-9]+\.[a-z]{2,4}$/, message: '邮箱格式不正确' }
        ],
        
        code: [{ required: true, message: '请填验证码', trigger: 'blur' }],
        fileIds: [{ required: true, message: '请上传代表作品', trigger: 'blur' }],
      }
    }
  },
  components: {
    ImgCode,
    Upload,
  },
  methods: {
    workChange(val) {
      this.workObj = val;
      this.form.fileIds = val.fileNumber;
    },
    handleCancel() {
      this.$store.dispatch('setRoleAuth', false);
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      let params = {
        applyRole: this.form.applyRole.join(','),
        info: this.form.info,
        workTitle: this.form.workTitle,
        description: this.form.description,
        phone: this.form.phone,
        email: this.form.email,
        code: this.form.code,
        uuid: this.form.uuid,
        fileIds: this.form.fileIds,
      }
      let response = await addAccount(params);
      if(response.code !== 200) {
        this.$message.error(response.msg);
        this.handleChangeCode();
        return false
      }
      this.$message.success(response.msg);
      this.$store.dispatch('refreshUserInfo')
      this.$store.dispatch('setRoleAuth', false);
    },
    handleChangeCode() {
      this.$refs.imgcode.captchaImage();
    },
    imgCodeChange(val) {
      this.form.uuid = val;
    },
  }
}
</script>

<style lang="scss" scoped>
.realname{
  .input-large{
    width: 480px;
  }
  .input-long{
    width: 280px;
  }
  .input-middle{
    width: 160px;
  }
  .input-small{
    width: 88px;
  }
  .mr{
    margin-right: 13px;
  }
  .mrl{
    margin-right: 24px;
  }
  .tip{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #C0C4CC;
    line-height: 17px;
    margin-top: 4px;
  }
  .upload-work{
    width: 128px;
    height: 128px;
    background: #F2F6FC;
    border-radius: 4px;
    border: 1px dashed #DCDFE6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
  .work-img{
    width: 128px;
    height: 128px;
    border-radius: 4px;
  }
  .el-checkbox-group{
    height: 40px;
  }
  .group-list{
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>