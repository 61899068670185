import Vue from "vue";
import Vuex from "vuex";
import { getUserInfo } from "@/api/index";

Vue.use(Vuex);

let userIfo = localStorage.getItem("userInfo") || "{}";

export default new Vuex.Store({
  state: {
    TOKEN: localStorage.getItem("TOKEN") || "",
    userInfo: JSON.parse(userIfo),
    loginDialog: {
      isShow: false,
      type: "login", // login/reg/find
    },
    showWallet: false, // 是否显示钱包
    showRollAuth: false,
    showRealNameAuth: false,
    applyType: 0, // 0:申请实名认证 1:申请重新认证
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.TOKEN = payload;
    },
    SET_USERINFO(state, payload) {
      state.userInfo = Object.assign({}, state.userInfo, payload);
    },
    SET_LOGDIALOG(state, payload) {
      state.loginDialog = Object.assign({}, state.loginDialog, payload);
    },
    LOG_OUT(state) {
      state.TOKEN = "";
      state.userInfo = "";
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("userInfo");
    },
    SET_WALLET_STATE(state, payload) {
      state.showWallet = payload;
    },
    SET_ROLLAUTH_STATE(state, payload) {
      state.showRollAuth = payload;
    },
    SET_REALNAME_STATE(state, payload) {
      state.showRealNameAuth = payload;
    },
    SET_APPLYTYPE(state, payload) {
      state.applyType = payload;
    },
  },
  actions: {
    async setToken({ commit, dispatch }, payload) {
      commit("SET_TOKEN", payload);
      localStorage.setItem("TOKEN", payload);
      await dispatch("refreshUserInfo");
    },
    // get user info
    refreshUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo({})
          .then((response) => {
            if (response.data) {
              localStorage.setItem("userInfo", JSON.stringify(response.data));
              commit("SET_USERINFO", response.data);
              resolve(response.data);
            }
            reject();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setLoginDialog(context, payload) {
      context.commit("SET_LOGDIALOG", payload);
    },
    logout(context) {
      context.commit("LOG_OUT");
      context.commit("SET_WALLET_STATE");
    },
    setWallet(context, payload) {
      context.commit("SET_WALLET_STATE", payload);
    },
    setRoleAuth(context, payload) {
      context.commit("SET_ROLLAUTH_STATE", payload);
    },
    setRealNameAuth(context, payload) {
      context.commit("SET_REALNAME_STATE", payload);
    },
    setUserInfo(context, payload) {
      context.commit("SET_USERINFO", payload);
    },
    setApplyType(context, payload) {
      context.commit("SET_APPLYTYPE", payload);
    },
    
  },
});
