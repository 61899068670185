<template>
  <div class="login" v-if="getDialogInfo.isShow">
    <div class="login-cover" @click="closeDialog"></div>
    <div class="login-contain">
      <div class="login-title">
        <img src="../../assets/images/logo-w.png" alt="">
      </div>
      <div class="login-close" @click="closeDialog">
        <i class="el-icon-close"></i>
      </div>
      <LoginForm v-if="getDialogInfo.type === 'login'" />
      <RegisterForm v-if="getDialogInfo.type === 'reg'" />
      <FindForm v-if="getDialogInfo.type === 'find'" />
    </div>
  </div>
</template>

<script>
import LoginForm from './LoginForm.vue'
import RegisterForm from './RegisterForm.vue'
import FindForm from './FindForm.vue'
export default {
  name: '',
  components: {
    LoginForm,
    RegisterForm,
    FindForm,
  },
  methods: {
    closeDialog() {
      this.$store.dispatch('setLoginDialog', {isShow: false});
    },
  },
  computed: {
    getDialogInfo() {
      return this.$store.state.loginDialog
    },
  }
}
</script>

<style lang="scss" scoped>
.login{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .login-cover{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.37);
  }
  .login-contain{
    position: relative;
    z-index: 2;
    min-width: 500px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 40px 50px;
    padding-top: 60px;
    .login-close{
      position: absolute;
      top: 0;
      right: 0;
      width: 52px;
      height: 52px;
      cursor: pointer;
      font-size: 24px;
      color: #909399;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .login-title{
      width: 272px;
      height: 60px;
      position: absolute;
      top: -30px;
      left: 50%;
      margin-left: -136px;
      background: url("../../assets/images/title.png") no-repeat;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        max-width: 80%;
        max-height:100%;
      }
    }
  }
}
</style>