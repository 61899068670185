import request from "@/api/request";

// login userType: 00 后台 01 前台
export function login(data) {
  return request({
    url: "/front/login/login?userType=01",
    method: "POST",
    data,
  });
}

// 获取验证码
// 非登录状态
export function getVCodeNoLogin(data) {
  return request({
    url: "/front/login/sendSmsVerifyCode",
    method: "POST",
    data,
  });
}
// 需要登录
export function getVCodeLogin(data) {
  return request({
    url: "/sms/sendSmsVerifyCode",
    method: "POST",
    data,
  });
}

// 注册
export function register(data) {
  return request({
    url: "/front/login/register",
    method: "POST",
    data,
  });
}
// 校验短信验证码
export function checkVCode(data) {
  return request({
    url: "/front/login/checkVerifyCode",
    method: "POST",
    data,
  });
}
// 忘记密码
export function forgetPassword(data) {
  return request({
    url: "/front/login/forgetPassword",
    method: "POST",
    data,
  });
}
// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: "/front/user/getInfo",
    method: "POST",
    data,
  });
}
// 获取钱包列表
export function getWalletList(data) {
  return request({
    url: "/front/wallet/getAll",
    method: "POST",
    data,
  });
}
// 新增钱包
export function addWallet(data) {
  return request({
    url: "/front/wallet/add",
    method: "POST",
    data,
  });
}
// 切换钱包
export function switchWallet(data) {
  return request({
    url: `/front/wallet/switch`,
    method: "POST",
    data,
  });
}
// 获取钱包详情
export function getWalletDetail(walletNumber) {
  return request({
    url: `/front/wallet/getInfo/${walletNumber}`,
    method: "POST",
  });
}
// 修改钱包地址
export function walletNameUpdate(data) {
  return request({
    url: `/front/wallet/updateName`,
    method: "POST",
    data,
  });
}
// 修改密码提示
export function walletRemindUpdate(data) {
  return request({
    url: `/front/wallet/updateRemind`,
    method: "POST",
    data,
  });
}
// 公示钱包
export function walletPublish(walletNumber) {
  return request({
    url: `/front/wallet/publish/${walletNumber}`,
    method: "POST",
  });
}
// 首页展示公示钱包
export function walletPublishIndex(data) {
  return request({
    url: `/front/wallet/publish`,
    method: "POST",
    data,
  });
}
// 设置默认钱包
export function walletSetDefault(walletNumber) {
  return request({
    url: `/front/wallet/default/${walletNumber}`,
    method: "POST",
  });
}
// 新增实名认证
export function addAuthentication(data) {
  return request({
    url: `/front/authentication/add`,
    method: "POST",
    data,
  });
}
// 查询实名认证列表
export function getRealNameAuthList(data) {
  return request({
    url: `/front/authentication/page`,
    method: "POST",
    data,
  });
}
// 根据id查询实名认证详情
export function getRealNameAuthDetailById(applyNumber) {
  return request({
    url: `/front/authentication/getInfo/${applyNumber}`,
    method: "POST",
  });
}

// 查询实名认证进度
export function getRNAuthSchedule(applyNumber) {
  return request({
    url: `/front/authentication/applySchedule/${applyNumber}`,
    method: "POST",
  });
}
// 查询实名认证的信息
export function getRealNameAuthDetail() {
  return request({
    url: `/front/authentication/getInfo`,
    method: "POST",
  });
}
// 撤回实名认证
export function revokeRNAuth(data) {
  return request({
    url: `/front/authentication/revokeApply`,
    method: "POST",
    data,
  });
}
// 查询签名列表
export function getSignList(data) {
  return request({
    url: `/front/sign/page`,
    method: "POST",
    data,
  });
}
// 获取开户申请通过的详细信息
export function getAccountInfo(data) {
  return request({
    url: `/front/account/getInfo`,
    method: "POST",
    data,
  });
}
// 获取图片验证码
export function captchaImage() {
  return request({
    url: `/captchaImage`,
    method: "GET",
  });
}
// 新增角色认证
export function addAccount(data) {
  return request({
    url: `/front/account/add`,
    method: "POST",
    data,
  });
}
// 角色认证申请列表
export function getAccountList(data) {
  return request({
    url: `/front/account/page`,
    method: "POST",
    data,
  });
}
// 角色认证详情
export function getAccountDetailById(applyNumber) {
  return request({
    url: `/front/account/getInfo/${applyNumber}`,
    method: "POST",
  });
}
// 角色认证进度
export function getRoleAuthSchedule(applyNumber) {
  return request({
    url: `/front/account/applySchedule/${applyNumber}`,
    method: "POST",
  });
}
// 撤回角色认证
export function revokeRoleAuth(data) {
  return request({
    url: `/front/account/revokeApply`,
    method: "POST",
    data,
  });
}
// 资产数量统计
export function assetsCount(data) {
  return request({
    url: `/front/assets/count`,
    method: "POST",
    data,
  });
}
// 资产列表
export function getAssetsList(data) {
  return request({
    url: `/front/assets/page`,
    method: "POST",
    data,
  });
}
// 查询转让批次分页列表
export function getBatchList(data) {
  return request({
    url: `/front/batch/page`,
    method: "POST",
    data,
  });
}
// 转让详情
export function batchIssueDetail(data) {
  return request({
    url: `/front/batch/issue/show`,
    method: "POST",
    data,
  });
}
// 签名申请详情
export function signDetail(productNumber) {
  return request({
    url: `/front/sign/detail/${productNumber}`,
    method: "POST",
  });
}
// 用户签名开始
export function signStart(data) {
  return request({
    url: `/front/sign/start`,
    method: "POST",
    data,
  });
}
// 用户签名结束
export function signDone(data) {
  return request({
    url: `/front/sign/done`,
    method: "POST",
    data,
  });
}
// 资产详情
export function getProductInfo(productNumber, detailNumber) {
  return request({
    url: `/front/product/getInfo/${productNumber}/${detailNumber}`,
    method: "POST",
  });
}
// 资产转让
export function batchAdd(data) {
  return request({
    url: `/front/batch/add`,
    method: "POST",
    data,
  });
}

// 资产转让进度
export function batchSchedule(transferNumber) {
  return request({
    url: `/front/batch/schedule/${transferNumber}`,
    method: "POST",
  });
}
// 流转记录
export function transferDetails(data) {
  return request({
    url: `/front/detail/transfer`,
    method: "POST",
    data,
  });
}
// 流转记录/dev-api/front/detail/chainOrigins/{productNumber}/{detailNumber}
export function chainOriginsDetails(productNumber, detailNumber) {
  return request({
    url: `/front/detail/chainOrigins/${productNumber}/${detailNumber}`,
    method: "POST",
  });
}
// 首页展示的产品列表 （无需登录）
export function productList(data) {
  return request({
    url: `/front/login/productList`,
    method: "POST",
    data,
  });
}
// 首页展示的产品详情 （无需登录）
export function productInfo(productNumber) {
  return request({
    url: `/front/login/productInfo/${productNumber}`,
    method: "POST",
  });
}
// 发行管理 产品详情
export function issProductInfo(productNumber) {
  return request({
    url: `/front/product/getInfo/${productNumber}`,
    method: "POST",
  });
}
// 转让待确认/受让待确认列表
export function assetsWaitList(data) {
  return request({
    url: `/front/assets/wait`,
    method: "POST",
    data,
  });
}
// 批量转让/拒绝
export function batchConfirm(data) {
  return request({
    url: `/front/batch/confirm`,
    method: "POST",
    data,
  });
}
// 查询单个交易记录
export function batchDetail(transferNumber) {
  return request({
    url: `/front/batch/detail/${transferNumber}`,
    method: "POST",
  });
}

// 获取当前钱包二维码
export function getUserQrCode(data) {
  return request({
    url: `/front/user/qrCode`,
    method: "POST",
    data,
  });
}
// 修改密码
export function updatePassword(data) {
  return request({
    url: `/front/user/updatePassword`,
    method: "POST",
    data,
  });
}
// 修改用户信息
export function updateUser(data) {
  return request({
    url: `/front/user/update`,
    method: "POST",
    data,
  });
}
// 获取待签名数量
export function getNeedSignCount(data) {
  return request({
    url: `/front/sign/need/sign`,
    method: "POST",
    data,
  });
}
// 发行管理-获取类目
export function taProductType(data) {
  return request({
    url: `/system/dict/data/type/ta_product_type`,
    method: "GET",
    data,
  });
}

// 上传
export function upload(data) {
  return request({
    url: `/file/upload`,
    method: "POST",
    data,
  });
}
// 上传头像
export function uploadAvatar(data) {
  return request({
    url: `/front/user/avatar`,
    method: "POST",
    data,
  });
}
// 身份证 ocr
export function ocrIdCard(data) {
  return request({
    url: `/file/ocrIdCard`,
    method: "POST",
    data,
  });
}
// 身份证 ocr
export function ocrrBusinessLicense(data) {
  return request({
    url: `/file/ocrBusinessLicense`,
    method: "POST",
    data,
  });
}

// 开始批量转让界面显示
export function assetstranStart(data) {
  return request({
    url: `/front/assets/start`,
    method: "POST",
    data,
  });
}
// 获取3d模型
export function getThreeModel(productNumber) {
  return request({
    url: `/front/product/getThreeModel/${productNumber}`,
    method: "GET",
  });
}

/////////////////////

// 区块列表
export function getWorkList(data) {
  return request({
    url: `/admin/collection/web/page`,
    method: "POST",
    data,
  });
}

// 详情
export function collectionInfo(collectionNumber) {
  return request({
    baseURL: process.env.VUE_APP_MP,
    url: `/front/index/collection/info/${collectionNumber}`,
    method: "POST",
  });
}

// 区块链公示
export function blockchain(data) {
  return request({
    baseURL: process.env.VUE_APP_MP,
    url: `/front/wallet/blockchain`,
    method: "POST",
    data,
  });
}
// 区块链公示 -产品
export function collection(data) {
  return request({
    baseURL: process.env.VUE_APP_MP,
    url: `/front/wallet/blockchain/collection`,
    method: "POST",
    data,
  });
}

// 区块链公示 -查看更多
export function geCollectionDetail(data) {
  return request({
    baseURL: process.env.VUE_APP_MP,
    url: `/front/wallet/blockchain/more/collection`,
    method: "POST",
    data,
  });
}

// 区块链公示 -查看更多
export function geCollectionDetailHead(walletNumber) {
  return request({
    baseURL: process.env.VUE_APP_MP,
    url: `/front/wallet/blockchain/more/collection/${walletNumber}`,
    method: "POST",
  });
}

// 首页banner获取
export function getPcBannerList() {
  return request({
    url: `/admin/advertConfig/pc/getBannerPictureList`,
    method: "POST",
  });
}
// 获取大师列表
export function getMasterList(data) {
  return request({
    url: `/admin/master/page`,
    method: "POST",
    data
  });
}

