<template>
  <div class="realname">
    <div class="realname-form">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px">
        <el-form-item label="账户类型:" prop="certificateType">
          <el-radio-group v-model="form.certificateType" size="small">
            <el-radio :label="1" border>个人账户</el-radio>
            <el-radio :label="2" border>机构账户</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.certificateType === 1">
          <Personal />
        </template>
        <template v-if="form.certificateType === 2">
          <Group />
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
// import VCode from '@/components/Vcode/index.vue'
// import Upload from '@/components/Upload/index.vue'
import Personal from './RealName/Personal.vue'
import Group from './RealName/Group.vue'
import { addAuthentication } from '@/api/index'

export default {
  name: "RealNameAuth",
  data() {
    return {
      form: {
        certificateType: 1, // 证件类型(1个人，2机构)
        institutionName: '',
        idNo: '',
        name: '',
        operatorName: '',
        phone: '',
        code: '',
        sex: 0,
      },
      idCardFaceId: {},
      idCardBackId: {},
      businessID:{},
      rules: {
        institutionName: [{ required: true, message: '请输入机构名称', trigger: 'blur' }],
        idNo: [{ required: true, message: '请输入证件号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        operatorName: [{ required: true, message: '请输入经办人姓名', trigger: 'blur' }],
      }
    }
  },
  components: {
    // VCode,
    // Upload,
    Personal,
    Group,
  },
  methods: {
    sfzzmOcr(val) {
      this.name = val.name;
      this.idNo = val.idNo;
      this.sex = val.sex === '男' ? 0 : 1;

    },
    sfzzmChange(val = {}) {
      this.idCardFaceId = val;
    },
    sfzfmChange(val = {}) {
      this.idCardBackId = val;
    },
    yyzzChange(val) {
      this.businessID = val;
    },
    confirmApply() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      let params = {};
      if(this.form.certificateType === 1) {
        params = {
          "applyType": 0,
          "authorizationFiles": '',
          "certificateType": this.form.certificateType,
          "code": this.form.code,
          "fileNos": `${this.idCardFaceId.fileNumber},${this.idCardBackId.fileNumber}`,
          "idNo": this.form.idNo,
          "institutionName": "",
          "name": this.form.name,
          "operatorName": "",
          "phone": this.form.phone,
          "sex": this.form.sex,
        }
      }
      if(this.form.certificateType === 2) {
        params = {
          "applyType": 0,
          "authorizationFiles": this.form.authorizationFiles,
          "certificateType": this.form.certificateType,
          "code": this.form.code,
          "fileNos": `${this.businessID.fileNumber}`,
          "idNo": this.form.idNo,
          "institutionName": this.form.institutionName,
          "name": this.form.name,
          "operatorName": this.form.operatorName,
          "phone": this.form.phone,
          "sex": '',
        }
      }
      this.addAuthentication(params);
    },
    async addAuthentication(params) {
      let response = await addAuthentication(params);
      if(response.code !== 200) {
        this.$message.error(response.msg)
        return false;
      }
      this.$message.success(response.msg)
      this.$store.dispatch('refreshUserInfo')
    },
  }
}
</script>

<style lang="scss" scoped>
.realname{
  .input-long{
    width: 280px;
  }
  .input-middle{
    width: 160px;
  }
  .mr{
    margin-right: 13px;
  }
  .tip{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #C0C4CC;
    line-height: 17px;
    margin-top: 4px;
  }
  .yyzz{
    width: 121px;
    height: 121px;
  }
  .sfz{
    width: 150px;
    height: 87px;
    // margin-right: 20px;
  }
  .imgborder{
    border-radius: 4px;
    border: 1px dashed #FF9100;
    margin-right: 10px;
    height: 97px;
    padding:5px;
  }
}
</style>