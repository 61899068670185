<template>
  <div class="user-info">
    <MenuList />
    <div class="line"></div>
    <AuthBtns />
  </div>
</template>

<script>
import MenuList from './MenuList.vue'
import AuthBtns from './AuthBtns.vue'
export default {
  name: "UserInfo",
  components: {
    AuthBtns,
    MenuList
  }
}
</script>

<style lang="scss" scoped>
.user-info{
  height: 100%;
  width: 208px;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #FFFFFF;
  .line{
    width: 100%;
    height: 1px;
    background: #F2F6FC;
  }
}
</style>