<template>
  <div class="auth-btns">
    <!--  v-if="getUserInfo.roles && getUserInfo.roles.length === 0" -->
    <button v-if="showRoleAuth" @click="setRoleAuth" class="auth-btn">
      <img
        class="icon"
        src="@/assets/images/role-auth-a.png"
        alt="申请角色认证"
      />
      申请角色认证
    </button>
    <button @click="setRealNameAuth" class="auth-btn" v-if="showRealName">
      <img
        class="icon"
        src="@/assets/images/name-auth-a.png"
        alt="申请实名认证"
      />
      申请实名认证
    </button>
  </div>
</template>

<script>
import { TIPS } from '@/config/index.js'
const repeatAuth = TIPS.repeatAuth;
export default {
  name: "AuthBtns",
  methods: {
    setRoleAuth() {
      if (this.getUserInfo.authStatus !== 1) {
        this.$confirm("需要先申请实名认证", "提示", {
          confirmButtonText: "去实名认证",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.setRealNameAuth();
          })
          .catch(() => {});
        return false;
      }
      if (this.getUserInfo.roleApplyType === 1) {
        this.$alert(repeatAuth, "提示", {
          confirmButtonText: "确定",
        })
          .then(() => {})
          .catch(() => {});
        return false;
      }
      this.$store.dispatch("setRoleAuth", true);
    },
    setRealNameAuth() {
      if (this.getUserInfo.authApplyType === 1) {
        this.$alert(repeatAuth, "提示", {
          confirmButtonText: "确定",
        })
          .then(() => {})
          .catch(() => {});
        return false;
      }
      this.$store.dispatch("setApplyType", 0);
      this.$store.dispatch("setRealNameAuth", true);
    },
  },
  computed: {
    showRealName() {
      return this.$store.state.userInfo.authStatus !== 1;
    },
    getUserInfo() {
      return this.$store.state.userInfo || {};
    },
    showRoleAuth() {
      return this.$store.state.userInfo.roles.length === 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-btns {
  padding: 24px 26px;
  .auth-btn {
    display: block;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid $pcolor;
    color: $pcolor;
    margin-bottom: 16px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .icon {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
</style>