<template>
  <div
    :class="
      $route.path === '/works' || $route.path === '/master' || $route.path === '/block-public' ? 'fixed' : ''
    "
  >
    <!--  :class="mode" -->
    <div
      class="nav-box"
      :class="{
        opacity: isOpacity,
        sfixed: $route.path === '/works' || $route.path === '/master' || $route.path === '/block-public',
      }"
    >
      <div class="nav">
        <div class="nav-left">
          <img
            @click="handleLogoClick"
            class="logo"
            src="@/assets/images/logo-new.png"
            alt=""
          />
        </div>
        <div class="nav-right">
          <div class="tabs-list">
            <div
              class="tabs-item pointer"
              :class="{ 'tabs-active': $route.name == 'Index' }"
              @click="$router.push({ name: 'Index' })"
            >
              首页
            </div>
            <div
              class="tabs-item pointer"
              :class="{ 'tabs-active': $route.name == 'Works' }"
              @click="$router.push({ name: 'Works' })"
            >
              作品
            </div>
            <!-- <div
              class="tabs-item pointer"
              :class="{ 'tabs-active': $route.name == 'Master' }"
              @click="$router.push({ name: 'Master' })"
            >
              大师
            </div> -->
            <div
              class="tabs-item pointer"
              :class="{ 'tabs-active': $route.name == 'BlockPublic' }"
              @click="$router.push({ name: 'BlockPublic' })"
            >
              区块链地址公示
            </div>
            <div class="tabs-item pointer" @click="handleToEX">
              区块链浏览器
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-space" v-if="!isOpacity"></div>
  </div>
</template>

<script>
import User from "./User.vue";
export default {
  name: "Nav",
  props: {
    mode: {
      type: String,
      default: "white",
    },
    isOpacity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFixed: false,
      offsetTop: 0,
    };
  },
  mounted() {
    // window.addEventListener('scroll', this.initHeight);
    // this.$nextTick(() => {
    //   //获取对象相对于版面或由 offsetTop 属性指定的父坐标的计算顶端位置
    //   this.offsetTop = document.querySelector('#boxFixed').offsetTop;
    // })
  },
  //回调中移除监听
  // destroyed() {
  //   window.removeEventListener('scroll', this.handleScroll)
  // },
  components: {
    User,
  },
  methods: {
    handleToEX() {
      window.open(process.env.VUE_APP_EXPLORER);
    },
    handleLogoClick() {
      this.$router.push({ name: "Index" });
    },
    toLogin(type = "login") {
      this.$store.dispatch("setLoginDialog", { isShow: true, type });
    },
    // initHeight() {
    //   // 设置或获取位于对象最顶端和窗口中可见内容的最顶端之间的距离 (被卷曲的高度)
    //   var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //   //如果被卷曲的高度大于吸顶元素到顶端位置 的距离
    //   this.isFixed = scrollTop > this.offsetTop ? true : false;
    // },
  },
  computed: {
    getToken() {
      return this.$store.state.TOKEN;
    },
    // getClass() {
    //   return `${this.mode} ${this.isFixed ? 'is_fixed' : "" }`
    // }
  },
};
</script>

<style lang="scss" scoped>
.nav-space {
  width: 100%;
  height: 120px;
}
.fixed {
  position: fixed;
  left: 0;
  top: 0;
  right: 6px;
  z-index: 10;
}
.nav-box {
  // width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding-top: 40px;
  &.sfixed {
    right: 6px;
  }
  .nav {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    height: 80px;
    border-radius: 40px;
    padding: 0 55px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    .nav-left {
      display: flex;
      align-items: center;
      .logo {
        width: 153px;
        height: 40px;
        cursor: pointer;
        background: url("~@/assets/images/Rectangle.png") no-repeat;
        background-size: contain;
      }
    }
    .nav-right {
      display: flex;
      align-items: center;

      .tabs-list {
        display: flex;
        color: #262626;
      }
      .tabs-item {
        height: 35px;
        line-height: 35px;
        margin: 0 14px;
        padding: 0 12px;
        min-width: 35px;
        border-bottom: 4px solid transparent;
        transition: 0.3s all;
        border-radius: 17px;
        font-size: 15px;
      }
      .tabs-active {
        background: rgba(255, 108, 26, 1);
        color: #fff;
        // border-bottom: 4px solid $pcolor;
        // font-size: 16px;
      }
      .btn-list {
        display: flex;
      }
    }
  }
  .btn {
    height: 30px;
    padding: 5px 20px;
    // border: 1px solid #fff;
    // color: #fff;
    border: 1px solid $pcolor;
    color: $pcolor;
    margin: 0 10px;
    border-radius: 15px;
  }
  .btn-primary {
    border: 1px solid $pcolor;
    background: $pcolor;
    color: #fff;
  }
}
// .white {
//   background: #fff;
//   box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
//   .tabs-item {
//     color: #262626;
//   }
//   .btn{
//     border: 1px solid $pcolor;
//     color: $pcolor;
//   }
//   .btn-primary{
//     color: #fff;
//   }
// }
// .dark {
//   background: rgba(0, 0, 0, 0.2);
// }
.is_fixed {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
}
.opacity {
  background: transparent;
}
</style>