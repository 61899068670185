<template>
  <ul class="menu-list">
    <li
      v-for="(item, index) of menuList"
      :key="index"
      :class="{ 'menu-item-active': getNowPathName === item.name }"
      class="menu-item"
      @click="handleClick(item)"
    >
      <div class="icon">
        <img v-if="getNowPathName !== item.name" :src="item.icon" alt="" />
        <img v-if="getNowPathName === item.name" :src="item.icona" alt="" />
      </div>
      {{ item.label }}
    </li>
    <!-- <li class="menu-item">
      <div class="icon"></div>
      发行管理
    </li>
    <li class="menu-item">
      <div class="icon"></div>
      转移记录
    </li>
    <li class="menu-item">
      <div class="icon"></div>
      账户管理
    </li>
    <li class="menu-item">
      <div class="icon"></div>
      认证记录
    </li> -->
  </ul>
</template>

<script>
export default {
  name: "MenuList",
  data() {
    return {};
  },
  methods: {
    handleClick(item) {
      if (item.name && item.name !== this.getNowPathName) {
        this.$router.push({
          name: item.name,
        });
      }
    },
  },
  computed: {
    getNowPathName() {
      return this.$route.name || "-";
    },
    menuList() {
      let userInfo = this.$store.state.userInfo;
      let list = [
        {
          label: "资产管理",
          name: "Assets",
          icon: require("@/assets/images/zcgl.png"),
          icona: require("@/assets/images/zcgl-a.png"),
        },
        {
          label: "转移记录",
          name: "Transfer",
          icon: require("@/assets/images/zyjl.png"),
          icona: require("@/assets/images/zyjl-a.png"),
        },
        {
          label: "账户管理",
          name: "Account",
          icon: require("@/assets/images/zhgl.png"),
          icona: require("@/assets/images/zhgl-a.png"),
        },
        {
          label: "认证记录",
          name: "AuthRecord",
          icon: require("@/assets/images/rzjl.png"),
          icona: require("@/assets/images/rzjl-a.png"),
        },
      ];
      if (userInfo.roles.length > 1) {
        list.splice(1, 0, {
          label: "发行管理",
          name: "Issuance",
          icon: require("@/assets/images/fxgl.png"),
          icona: require("@/assets/images/fxgl-a.png"),
        });
      }
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-list {
  padding: 24px 0;
  .menu-item {
    width: 100%;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #262626;
    line-height: 20px;
    border-radius: 0 20px 20px 0;
    padding-left: 54px;
    .icon {
      width: 16px;
      height: 16px;
      // background: #eee;
      margin-right: 31px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .menu-item-active {
    background: #fff7e6;
    color: $pcolor;
  }
}
</style>