import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { NO_AUTH } from "./config/index";
import { checkLogin } from "./utils/index";
import "@/directives/index";

import ElementUI from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/style/theme/index.css";
// import VConsole from 'vconsole';
// const vConsole = new VConsole({ maxLogNumber: 1000 });
// console.log(vConsole)

Vue.config.productionTip = false;

Vue.use(ElementUI, { size: "middle", zIndex: 100 });
router.beforeEach((to, from, next) => {
  console.log(to.name, NO_AUTH)
  if (NO_AUTH.indexOf(to.name) == -1 && !checkLogin()) {
    store.dispatch("setLoginDialog", { isShow: true, type: "login" });
  } else {
    next();
  }
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
