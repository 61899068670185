<template>
  <div class="realname">
    <div class="realname-form">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px">
        <el-form-item label="营业执照:" prop="fileNos">
          <Upload ocr="businessLicense" @onChange="yyzzChange" @onOcr="yyzzOcr">
            <div class="imgborder">
              <!-- <img v-if="!idCardFace.path" src="@/assets/images/sfzzm.png" alt="身份证正面" class="sfz">
              <el-image class="sfz" v-if="idCardFace.path" :src="idCardFace.path" fit="cover" ></el-image> -->
              <img
                v-if="businessID.path"
                :src="businessID.path"
                alt="营业执照"
                class="yyzz"
              />
              <img
                v-if="!businessID.path"
                src="@/assets/images/yyzz.png"
                alt="营业执照"
                class="yyzz"
              />
            </div>
          </Upload>
          <p class="hint">仅支持上传图片格式</p>
          <!-- <el-input clearable class="input-long" v-model="form.name"></el-input> -->
        </el-form-item>
        <el-form-item label="机构名称:" prop="institutionName">
          <el-input
            clearable
            class="input-long"
            v-model="form.institutionName"
          ></el-input>
        </el-form-item>
        <el-form-item label="社会统一信用代码:" prop="idNo">
          <el-input clearable class="input-long" v-model="form.idNo"></el-input>
        </el-form-item>
        <el-form-item label="法人姓名:" prop="name">
          <el-input
            clearable
            class="input-middle"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="经办人姓名:" prop="operatorName">
          <el-input
            clearable
            class="input-middle"
            v-model="form.operatorName"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号:" prop="phone">
          <el-input
            clearable
            class="input-middle"
            v-model="form.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码:" prop="code">
          <el-input
            clearable
            class="input-middle mr"
            v-model="form.code"
          ></el-input>
          <VCode
            type="APPLY_AUTH"
            :phone="form.phone"
            btnType="normal"
            style="display: inline-block"
          />
        </el-form-item>
        <el-form-item label="授权委托书:" prop="authorizationFiles">
          <!-- <Upload>
            <img src="@/assets/images/yyzz.png" alt="营业执照" class="yyzz">
          </Upload> -->
          <el-link
            v-show="filePath"
            :href="filePath"
            target="_blank"
            :underline="false"
            >查看<i class="el-icon-view el-icon--right"></i>
          </el-link>
          <Upload accept=".jpg,.jpeg,.png,.jpe,.pdf" @onChange="authBookChange">
            <el-button
              v-if="!form.authorizationFiles"
              type="primary"
              class="input-middle"
              icon="el-icon-upload"
              >上传</el-button
            >
            <el-button v-if="form.authorizationFiles" type="primary" plain
              >重新上传</el-button
            >
          </Upload>

          <el-button
            type="text"
            class="input-middle"
            icon="el-icon-download"
            @click="downloadFun"
            >下载授权委托书模板</el-button
          >
          <p class="tip">
            委托授权书请加盖公章，以pdf或jpg格式上传，文件大小不超过1m。
          </p>
        </el-form-item>
      </el-form>
    </div>
    <div class="dialog-btn">
      <el-button @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="confirmApply">提交申请</el-button>
    </div>
  </div>
</template>

<script>
import VCode from "@/components/Vcode/index.vue";
import Upload from "@/components/Upload/index.vue";
import { addAuthentication } from "@/api/index";
import { AUTH_TEMPLATE } from "@/config/index";

export default {
  name: "Group",
  data() {
    return {
      // authTemplate: AUTH_TEMPLATE,
      filePath: "",
      form: {
        authorizationFiles: "",
        certificateType: 2, // 证件类型(1个人，2机构)
        institutionName: "",
        idNo: "",
        name: "",
        operatorName: "",
        phone: "",
        code: "",
        sex: 0,
        fileNos: "",
      },
      // idCardFaceId: {},
      // idCardBackId: {},
      authBookObj: {},
      businessID: {},
      rules: {
        institutionName: [
          { required: true, message: "请输入机构名称", trigger: "blur" },
        ],
        idNo: [{ required: true, message: "请输入证件号", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: "手机号格式不正确",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        operatorName: [
          { required: true, message: "请输入经办人姓名", trigger: "blur" },
        ],
        authorizationFiles: [
          { required: true, message: "请上传授权委托书", trigger: "blur" },
        ],
        fileNos: [
          { required: true, message: "请上传证件照片", trigger: "blur" },
        ],
      },
    };
  },
  components: {
    VCode,
    Upload,
  },
  methods: {
    authBookChange(val) {
      this.authBookObj = val;
      this.filePath = val.path;
      this.form.authorizationFiles = val.fileNumber;
    },
    downloadFun() {
      location.href = AUTH_TEMPLATE;
    },
    handleCancel() {
      this.$store.dispatch("setRealNameAuth", false);
    },
    yyzzOcr(val) {
      this.form.institutionName = val.name;
      this.form.idNo = val.regNum;
      this.form.name = val.person;
    },

    yyzzChange(val) {
      this.businessID = val;
      this.form.fileNos = val.fileNumber;
    },
    confirmApply() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      let params = {
        applyType: this.$store.state.applyType || 0,
        authorizationFiles: this.form.authorizationFiles,
        certificateType: this.form.certificateType,
        code: this.form.code,
        fileNos: `${this.businessID.fileNumber}`,
        idNo: this.form.idNo,
        institutionName: this.form.institutionName,
        name: this.form.name,
        operatorName: this.form.operatorName,
        phone: this.form.phone,
        sex: "",
      };
      this.addAuthentication(params);
    },
    async addAuthentication(params) {
      let response = await addAuthentication(params);
      if (response.code !== 200) {
        this.$message.error(response.msg);
        return false;
      }
      this.$message.success(response.msg);
      this.$store.dispatch("refreshUserInfo");
      this.$store.dispatch("setRealNameAuth", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.hint {
  color: #ff9100;
  font-size: 13px;
  line-height: 25px;
}
.realname {
  .input-long {
    width: 280px;
  }
  .input-middle {
    width: 160px;
  }
  .mr {
    margin-right: 13px;
  }
  .tip {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c0c4cc;
    line-height: 17px;
    margin-top: 4px;
  }
  .yyzz {
    width: 121px;
    height: 121px;
  }
  .sfz {
    width: 150px;
    height: 87px;
    // margin-right: 20px;
  }
  .imgborder {
    border-radius: 4px;
    border: 1px dashed #ff9100;
    margin-right: 10px;
    height: 128px;
    padding: 5px;
  }
}
</style>