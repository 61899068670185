<template>
  <div class="loginform">
    <LoginTitle title="密码登录">
      <span @click="toReg">注册账号</span>
    </LoginTitle>
    <Input
      iconType="phone"
      :maxlength="400"
      v-model="username"
      placeholder="请输入账户名 / 手机号"
    ></Input>
    <Input
      iconType="pwd"
      v-model="password"
      placeholder="请输入密码"
      :showToggle="true"
      type="password"
    ></Input>
    <div class="img-code-box">
      <Input
        iconType="code"
        v-model="form.code"
        style="margin-bottom:0;width:259px;"
        placeholder="请输入图形验证码"
        type="text"
      ></Input>
      <ImgCode style="width: 125px; height: 46px;border-radius: 23px;" @onChange="imgCodeChange" ref="imgcode" />
    </div>
    
    <!-- <div class="tip">
      <span @click="toFind">忘记密码</span>
    </div> -->
    <el-button @click="handleLogin" style="width: 100%" type="primary" round
      >登录</el-button
    >
    <div class="tip">
      <span @click="toFind">忘记密码</span>
    </div>
    <!-- <el-dialog
      title="验证"
      width="35%"
      append-to-body
      :visible.sync="dialogFormVisible"
    >
      <el-form v-if="dialogFormVisible" :model="form">
        <el-form-item label="验证码:" prop="code">
          <el-input
            clearable
            class="input-small mrl"
            v-model="form.code"
          ></el-input>
          <ImgCode @onChange="imgCodeChange" ref="imgcode" />
          <el-button @click="handleChangeCode" type="text" size="mini">
            换一张
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="verityImgCode">确 定</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import ImgCode from "@/components/Vcode/ImgCode";
import LoginTitle from "./LoginTitle.vue";
import Input from "./Input.vue";
import { login } from "@/api";
export default {
  name: "LoginForm",
  components: {
    LoginTitle,
    Input,
    ImgCode,
  },
  data() {
    return {
      username: "",
      password: "",
      dialogFormVisible: false,
      form: {
        code: "",
        uuid: "",
      },
    };
  },
  methods: {
    verityImgCode() {
      if (this.form.code.trim() === "") {
        this.$message.error("请填写图形验证码");
        return status;
      }
      this.dialogFormVisible = false;
      this.login();
    },
    handleChangeCode() {
      this.$refs.imgcode.captchaImage();
    },
    imgCodeChange(val) {
      this.form.uuid = val;
    },
    toReg() {
      this.$store.dispatch("setLoginDialog", { isShow: true, type: "reg" });
    },
    toFind() {
      this.$store.dispatch("setLoginDialog", { isShow: true, type: "find" });
    },
    handleLogin() {
      if (this.username.trim() === "") {
        this.$message.warning("请输入账户名 / 手机号");
        return status;
      }
      if (this.password.trim() === "") {
        this.$message.warning("请输入密码");
        return status;
      }
      if (this.form.code.trim() === "") {
        this.$message.warning("请输入验证码");
        return status;
      }
      this.login();
    },
    async login() {
      let params = {
        username: this.username,
        password: this.password,
        code: this.form.code,
        uuid: this.form.uuid,
      };
      let response = await login(params);
      if (response.code !== 200) {
        this.$message.error(response.msg);
        this.handleChangeCode();
        return;
      }
      this.$message.success("登录成功");
      this.$store.dispatch("setToken", response.data);
      this.$store.dispatch("setLoginDialog", { isShow: false });
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-small {
  width: 150px;
}
.mr {
  margin-right: 13px;
}
.mrl {
  margin-right: 24px;
}
.tip {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF9100;
  line-height: 20px;
  margin-top: 30px;
  span {
    cursor: pointer;
  }
}
.img-code-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
  height: 46px;
}
</style>