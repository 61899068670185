<template>
  <div class="login-title">
    <div class="ltitle">{{title}}</div>
    
    <div class="sub-title"><slot/></div>
  </div>
</template>

<script>
export default {
  name: "LoginTitle",
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.login-title{
  height: 33px;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  .ltitle{
    font-weight: 500;
    color: #262626;
    line-height: 33px;
    font-size: 24px;
    font-family:  PingFang SC;
  }
  .sub-title{
    height: 25px;
    font-weight: 100;
    color: #FF9100;
    line-height: 25px;
    margin-left: 24px;
    font-size: 18px;
    cursor: pointer;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}
</style>