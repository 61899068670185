<template>
  <div class="tag-item" :class="type"><slot/></div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-item{
  padding: 0 8px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  border-radius: 11px;
  // margin-right: 8px;
  // margin-bottom: 17px;
  background: #FFF7E6;
  border: 1px solid #FFD591;
  color: #FF9100;
}
.error{
  border: 1px solid #FFCCC7;
  background: #FFF1F0;
  color: #FF4D4F;
}
.normal{
  border: 1px solid #8FCEFF;
  background: #DCF0FF;
  color: #008DFF;
}
.gray{
  border: 1px solid #DCDFE6;
  background: #F5F7FA;
  color: #DCDFE6;
}
</style>