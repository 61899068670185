<template>
  <div>
    <img class="img" :src="imgBase64" alt="">
    <div class="dialog-btn">
      <el-button type="primary" @click="$emit('onClose')">关闭</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageDialog",
  data() {
    return {
      imgBase64: null,
    }
  },
  beforeDestroy() {
    this.imgBase64 = null;
  },
  mounted() {
    this.imgBase64 = `data:image/png;base64,${this.$store.state.userInfo.qrCode}`;
  },
}
</script>

<style lang="scss" scoped>
.img{
  width: 250px;
  height: 250px;
  display: block;
  margin: 0 auto;
}
</style>