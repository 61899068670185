<template>
  <div class="layout">
    <div class="left">
      <UserInfo/>
    </div>
    <div class="right">
      <transition name="fade-transform" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>
<script>

import UserInfo from './UserInfo.vue'
export default {
  name: 'Layout',
  components: {
    UserInfo,
  }
}
</script>

<style lang="scss" scoped>
.layout{
  display: flex;
  width: 1200px;
  margin: 0 auto;
  // background: #fff000;
  justify-content: space-between;
  padding: 30px 0;
  .left{
    margin-right: 24px;
  }
  .right{
    width: 986px;
  }
}
</style>