<template>
  <div class="wallet">
    <!-- <div class="space"></div> -->
    <div v-if="walletIsEmpty" class="w-contain w-empty">
      <img src="@/assets/images/empty.png" alt="">
      <p class="empty-tip">未创建区块链地址</p>
      <div @click="realnameVisible = true" class="empty-btn">立即创建</div>
    </div>
    <!-- 标题 -->
    <template v-if="!walletIsEmpty">
      <el-popover
        placement="left-start"
        @show="showWalletList"
        @hide="hideWalletList"
        width="320"
        trigger="click"
      >
        <div class="title" slot="reference">
          <i class="icon el-icon-caret-left" :class="{'trun-icon' : !walletListIsShow}"></i>
          <span class="show" style="margin-right: 10px">{{ walletInfo.name }}</span>
          <Tag type="gray" v-if="walletInfo.publicShow === 0">未公示</Tag>
          <Tag v-if="walletInfo.publicShow === 1">已公示</Tag>
        </div>
        <List ref="walletList" @onChange="switchWallet" />
      </el-popover>
      <div v-if="!walletIsEmpty" class="w-contain">
        

        <!-- 资产 -->
        <div class="assets">
          <p class="atitle">我的资产</p>
          <div class="acount">
            {{ assetsInfo.allCount || "0" }}<span>个</span>
          </div>
        </div>
        <div class="line"></div>
        <!-- 区块链信息 -->
        <div class="chaincode">
          <!-- <Item class="mb" :showText="true" :showSlot="true" label="" v-model="val1">1</Item>
          <Item class="mb" :showText="true" label="密码提示" type="password" v-model="val1">1</Item>
          <Item class="mb" label="区块链地址二维码">1</Item>
          <Item label="设为默认区块链地址">1</Item> -->
          <div
            class="item"
            :class="{ 'item-actived': isEditName }"
            style="margin-bottom: 10px"
          >
            <div class="left">
              <p class="label">区块链地址名称</p>
              <div class="inputarea" v-if="!isEditName">{{ wallet.name }}</div>
              <input
                ref="isNameInput"
                @keyup.enter="changeNameHandle"
                v-if="isEditName"
                v-model="wallet.name"
                placeholder="placeholder"
                class="inputarea"
                type="text"
              />
            </div>
            <div class="right">
              <div class="icon" @click="isEditName = true" v-if="!isEditName">
                <p class="icon-img editicon"></p>
              </div>
              <el-button
                v-if="isEditName"
                size="mini"
                type="primary"
                @click="changeNameHandle"
                round
                >提交</el-button
              >
            </div>
          </div>

          <div class="item" style="margin-bottom: 10px">
            <div class="left">
              <p class="label">区块链地址ID</p>
              <div class="inputarea">{{ walletInfo.walletNumber }}</div>
            </div>
          </div>

          <div class="item" style="margin-bottom: 10px">
            <div class="left">
              <p class="label">区块链地址</p>
              <div class="inputarea">{{ walletInfo.address }}</div>
            </div>
          </div>

          <div
            class="item"
            :class="{ 'item-actived': isEditPassword }"
            style="margin-bottom: 10px"
          >
            <div class="left">
              <p class="label">密码提示</p>
              <div class="inputarea" v-if="!isEditPassword">
                {{ wallet.remind }}
              </div>
              <input
                ref="passwordInput"
                v-if="isEditPassword"
                @keyup.enter="walletRemindUpdate"
                v-model="wallet.remind"
                placeholder="placeholder"
                class="inputarea"
                type="text"
              />
            </div>
            <div class="right">
              <div
                class="icon"
                v-if="!isEditPassword"
                @click="isEditPassword = true"
              >
                <p class="icon-img editicon"></p>
              </div>
              <el-button
                v-if="isEditPassword"
                size="mini"
                type="primary"
                @click="walletRemindUpdate"
                round
                >提交</el-button
              >
            </div>
          </div>
          <div class="item" style="margin-bottom: 10px">
            <div class="left">
              <p class="label">区块链地址二维码</p>
            </div>
            <div class="right">
              <div class="icon" @click="arcodeVisible = true">
                <!-- <img class="icon-img" src="@/assets/images/code.png" alt="编辑" /> -->
                <p class="icon-img qrcodeicon"></p>
              </div>
            </div>
          </div>
          <!-- <div class="item" style="margin-bottom: 10px;">
            <div class="left">
              <p class="label">设为默认区块链地址</p>
            </div>
            <div class="right" >
              <el-button :disabled="walletInfo.defaultWallet === 1" @click="walletSetDefault" type="text" size="mini">设为默认地址</el-button>
            </div>
          </div> -->
        </div>
        
      </div>
      <div class="btn-box">
        <button @click="handlePublish" v-if="walletInfo.publicShow !== 1" class="btn">公示区块链地址</button>
        <button v-if="walletInfo.publicShow === 1" class="btn btn-disabled">已公示区块链地址</button>
        <button @click="walletSetDefault" v-if="walletInfo.defaultWallet !== 1" class="btn">设为默认区块链地址</button>
        <button v-if="walletInfo.defaultWallet === 1" class="btn btn-disabled">当前为默认地址</button>
      </div>
    </template>
    <!-- 二维码弹窗 -->
    <el-dialog
      title="区块链地址二维码"
      append-to-body
      :visible.sync="arcodeVisible"
      width="500px"
    >
      <ImageDialog v-if="arcodeVisible" @onClose="arcodeVisible = false" />
    </el-dialog>
    <!-- 弹窗 -->
    <el-dialog append-to-body title="添加区块链地址" :visible.sync="realnameVisible" width="500px">
      <Add @onChange="handleAdd" @onCancel="realnameVisible = false"/>
    </el-dialog>
  </div>
</template>

<script>
import {
  getWalletDetail,
  assetsCount,
  walletSetDefault,
  walletNameUpdate,
  walletRemindUpdate,
  walletPublish,
} from "@/api/index";
import Tag from "@/components/Tag";
import List from "./List";
import ImageDialog from "./ImageDialog.vue";
import Add from './Add.vue'
import { TIPS } from '@/config/index.js'
const repeatAuth = TIPS.repeatAuth;
export default {
  name: "Wallet",
  components: {
    Tag,
    List,
    ImageDialog,
    Add,
  },
  data() {
    return {
      isEditName: false, // 是否修改名字
      isEditPassword: false, // 是否修改密码
      arcodeVisible: false,
      wallet: {
        name: "",
        walletNumber: "",
        address: "",
        defaultWallet: false,
        remind: "",
      },
      walletInfo: {}, // 当前钱包信息
      assetsInfo: {}, // 资产信息
      value: false,
      walletListIsShow: false,
      realnameVisible: false,
    };
  },
  mounted() {
    this.getWalletDetail();
  },
  methods: {
    handleAdd() {
      this.realnameVisible = false;
      this.$store.dispatch('refreshUserInfo');
      this.getWalletDetail();
    },
    switchWallet(walletNumber) {
      this.isEditName = false;
      this.isEditPassword = false;
      this.getWalletDetail(walletNumber);
    },
    showWalletList() {
      this.walletListIsShow = true;
      this.$refs.walletList.getWalletList();
    },
    hideWalletList(){
      this.walletListIsShow = false;
    },
    handlePublish() {
      // 未实名认证的提示先实名认证
      if (this.$store.state.userInfo.authStatus !== 1) {
        this.$confirm(
          "公示区块链地址需要先实名认证，当前账户未实名认证,是否立即申请实名认证？",
          "公示区块链地址",
          {
            confirmButtonText: "申请实名认证",
            cancelButtonText: "取消",
            center: true,
            callback: (action) => {
              if (action === "confirm") {
                this.$store.dispatch("showWallet", false);
                let { userInfo } = this.$store.state;
                if (userInfo.authApplyType === 1) {
                  this.$alert(repeatAuth, "提示", {
                    confirmButtonText: "确定",
                  })
                    .then(() => {})
                    .catch(() => {});
                  return false;
                }
                // 打开实名认证弹窗
                this.$store.dispatch("setRealNameAuth", true);
              }
            },
          }
        );
      } else {
        this.$confirm(
          "公示区块链地址后，公众将能查看该区块链地址中的NFT资产，且公示后无法撤销，是否确认公示区块链地址？",
          "公示区块链地址",
          {
            confirmButtonText: "确认公示",
            cancelButtonText: "取消",
            center: true,
            callback: (action) => {
              if (action === "confirm") {
                this.walletPublish();
                this.$refs.walletList.getWalletList();
              }
            },
          }
        );
      }
    },
    // 公示钱包
    async walletPublish() {
      let response = await walletPublish(this.walletInfo.walletNumber);
      if (response.code !== 200) {
        this.$message.error({ message: response.msg, customClass: "_zindex" });
        return false;
      }
      this.getWalletDetail();
      this.$message.success({ message: response.msg, customClass: "_zindex" });
    },
    // 修改钱包名称
    async changeNameHandle() {
      let params = {
        name: this.wallet.name,
        walletNumber: this.walletInfo.walletNumber,
      };
      let response = await walletNameUpdate(params);
      if (response.code !== 200) {
        this.$message.error(response.msg);
        return false;
      }
      this.getWalletDetail();
      this.$message.success(response.msg);
      this.isEditName = false;
    },
    // 修改密码提示
    async walletRemindUpdate() {
      let params = {
        remind: this.wallet.remind,
        walletNumber: this.walletInfo.walletNumber,
      };
      let response = await walletRemindUpdate(params);
      if (response.code !== 200) {
        this.$message.error(response.msg);
        return false;
      }
      this.getWalletDetail();
      this.$message.success(response.msg);
      this.isEditPassword = false;
    },
    // 设置默认钱包
    async walletSetDefault() {
      this.$confirm(
        "设置为默认地址后，登录时将默认登录该地址，是否确认设置当前地址为默认地址？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }
      )
        .then(async () => {
          let response = await walletSetDefault(this.walletInfo.walletNumber);
          if (response.code !== 200) {
            this.$message.error({
              message: response.msg,
              customClass: "_zindex",
            });
            return false;
          }
          this.$message.success({
            message: response.msg,
            customClass: "_zindex",
          });
          // this.$store.dispatch('refreshUserInfo');
          this.getWalletDetail();
          // this.$message.success(response.msg);
        })
        .catch(() => {});
    },
    // 获取钱包详情
    async getWalletDetail(number) {
      let walletNumber = number || this.$store.state.userInfo.walletNumber;
      let response = await getWalletDetail(walletNumber);
      if (response.code !== 200) {
        this.$message.error(response.msg);
        return false;
      }
      this.walletInfo = response.data;
      this.wallet.name = response.data.name;
      this.wallet.remind = response.data.remind;
      this.wallet.defaultWallet = response.data.defaultWallet;
      this.assetsCount();
    },
    // 获取资产详情
    async assetsCount() {
      let response = await assetsCount();
      if (response.code !== 200) {
        this.$message.error(response.msg);
        return false;
      }
      this.assetsInfo = response.data;
    },
  },
  watch: {
    isEditName(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.isNameInput.focus();
        });
    },
    isEditPassword(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.passwordInput.focus();
        });
    },
  },
  computed: {
    walletIsEmpty() {
      return !this.$store.state.userInfo.walletNumber
    }
  }
};
</script>

<style lang="scss" scoped>
.space {
  height: 80px;
  width: 100%;
}
.wallet {
  // padding-top: 80px;
  // height: calc;
  max-height: calc(100vh - 60px);
  height: 100%;
  // background: #f00;
  overflow: auto;
  // position: relative;
  display: flex;
  flex-direction: column;
}
.w-contain::-webkit-scrollbar { width: 0 !important }
.w-contain {
  padding: 15px;
  // height: calc(100vh - 80px);
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}
.w-empty{
  width: 418px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img{
    width: 245px;
    height: 156px;
    margin-bottom: 10px;
  }
  .empty-tip{
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #909399;
    line-height: 20px;
    text-align: center;
    margin-bottom: 100px;
  }
  .empty-btn{
    width: 160px;
    height: 40px;
    line-height: 40px;
    background: #FF9100;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
  }
}
.title {
  // height: 28px;
  font-size: 18px;
  font-family:  PingFang SC;
  font-weight: 500;
  color: #262626;
  line-height: 28px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.3s;
  padding: 15px;
  &:hover{
    color: #FF9100;
  }
  .icon {
    font-size: 20px;
    color: #909399;
    transition: all 0.3s;
  }
  .trun-icon{
    transform: rotate(180deg);
  }
}
.assets {
  width: 100%;
  height: 90px;
  background: #f5f7fa;
  border-radius: 8px;
  padding: 11px 20px;
  margin-bottom: 20px;
  .atitle {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #909399;
  }
  .acount {
    height: 56px;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff9100;
    line-height: 56px;
    text-align: center;
    span {
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
      line-height: 17px;
      margin-left: 7px;
    }
  }
}
.line {
  width: 100%;
  height: 2px;
  // background: #F2F6FC;
  background: #f2f6fc;
}
.chaincode {
  margin: 20px 0 57px;
  .mb {
    margin-bottom: 12px;
  }
}
.item {
  padding: 18px 20px 19px;
  border-radius: 10px;
  border: 1px solid #dcdfe6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 58px;
  .left {
    width: 100%;
    .label {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
      line-height: 22px;
      // margin-bottom: 12px;
    }
    .inputarea {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 20px;
      word-break: break-all;
      width: 100%;
      border: none;
      outline: none;
      margin-top: 12px;
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    .icon {
      width: 32px;
      height: 32px;
      // background: #f00;
      margin-left: 18px;
    }
  }
  .icon-img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .editicon{
    background: url('../../assets/images/edit.png') no-repeat;
    background-size: contain;
    &:hover{
      background: url('../../assets/images/edit-a.png') no-repeat;
      background-size: contain;
    }
  }
  .qrcodeicon{
    background: url('../../assets/images/code.png') no-repeat;
    background-size: contain;
    &:hover{
      background: url('../../assets/images/code-a.png') no-repeat;
      background-size: contain;
    }
  }
}
.item-actived {
  border-color: $pcolor;
}
.btn-box {
  display: flex;
  justify-content: space-between;
  // width: 378px;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;
  background: #fff;
  padding: 18px;
  box-sizing: border-box;
  .btn {
    width: 50% !important;
    height: 40px !important;
    border-radius: 20px;
    background: $pcolor;
    color: #fff;
    flex-grow: 1;
    flex-shrink: 1;
    cursor: pointer;
    &:last-child{
      margin-left: 15px;
    }
  }
  .btn-disabled{
    background: rgba(0, 0, 0, 0.1);
    color: rgba(96, 98, 102, 1);
  }
}
</style>