<template>
  <div class="realname">
    <div class="realname-form">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px">
        
        <el-form-item label="证件照片:" prop="">
          <div class="flex">
            <Upload ocr="idCard" @onChange="sfzzmChange" @onOcr="sfzzmOcr">
              <div class="imgborder">
                <img v-if="!idCardFace.path" src="@/assets/images/sfzzm.png" alt="身份证正面" class="sfz">
                <el-image class="sfz" v-if="idCardFace.path" :src="idCardFace.path" fit="cover" ></el-image>
              </div>
              
            </Upload>
            <Upload @onChange="sfzfmChange">
              <div class="imgborder">
                <img v-if="!idCardBack.path" src="@/assets/images/sfzfm.png" alt="身份证反面" class="sfz">
                <el-image class="sfz" v-if="idCardBack.path" :src="idCardBack.path" fit="cover" ></el-image>
              </div>
            </Upload>
          </div>
          <p class="hint">仅支持上传图片格式</p>
        </el-form-item>
        
        <el-form-item label="姓名:" prop="name">
          <el-input clearable class="input-middle" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="身份证号码:" prop="idNo">
          <el-input clearable class="input-middle" v-model="form.idNo"></el-input>
        </el-form-item>
        <el-form-item label="性别:" prop="sex">
          <el-radio-group v-model="form.sex" size="small">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号:" prop="phone">
          <el-input disabled clearable class="input-middle" v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="验证码:" prop="code">
          <el-input clearable class="input-middle mr" v-model="form.code"></el-input>
          <VCode type="APPLY_AUTH" :phone="form.phone" btnType="normal" style="display:inline-block;" />
        </el-form-item>
        
      </el-form>
    </div>
    <div class="dialog-btn">
      <el-button @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="confirmApply">提交申请</el-button>
    </div>
  </div>
</template>

<script>
import VCode from '@/components/Vcode/index.vue'
import Upload from '@/components/Upload/index.vue'
import { addAuthentication } from '@/api/index'

export default {
  name: "Personal",
  data() {
    return {
      form: {
        certificateType: 1,
        institutionName: '',
        idNo: '',
        name: '',
        operatorName: '',
        phone: this.$store.state.userInfo.phonenumber,
        code: '',
        sex: 0,
      },
      idCardFace: {},
      idCardBack: {},
      businessID:{},
      rules: {
        institutionName: [{ required: true, message: '请输入机构名称', trigger: 'blur' }],
        idNo: [{ required: true, message: '请输入证件号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/, message: '手机号格式不正确' }
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        operatorName: [{ required: true, message: '请输入经办人姓名', trigger: 'blur' }],
      }
    }
  },
  components: {
    VCode,
    Upload,
  },
  methods: {
    handleCancel() {
      this.$store.dispatch('setRealNameAuth', false);
    },
    sfzzmOcr(val) {
      
      this.form.name = val.name;
      this.form.idNo = val.num;
      this.form.sex = val.sex === '男' ? 0 : 1;

    },
    sfzzmChange(val = {}) {
      this.idCardFace = val;
    },
    sfzfmChange(val = {}) {
      this.idCardBack = val;
    },
    // yyzzChange(val) {
    //   this.businessID = val;
    // },
    confirmApply() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      let params = params = {
        "applyType": this.$store.state.applyType || 0,
        "authorizationFiles": '',
        "certificateType": this.form.certificateType,
        "code": this.form.code,
        "fileNos": `${this.idCardFace.fileNumber},${this.idCardBack.fileNumber}`,
        "idNo": this.form.idNo,
        "institutionName": "",
        "name": this.form.name,
        "operatorName": "",
        "phone": this.form.phone,
        "sex": this.form.sex,
      }
      
      this.addAuthentication(params);
    },
    async addAuthentication(params) {
      let response = await addAuthentication(params);
      if(response.code !== 200) {
        this.$message.error(response.msg)
        return false;
      }
      this.$message.success(response.msg)
      this.$store.dispatch('refreshUserInfo')
      this.$store.dispatch('setRealNameAuth', false);
    },
  }
}
</script>

<style lang="scss" scoped>
.hint{
  color: #ff9100;
  font-size: 13px;
  line-height: 25px;
}
.realname{
  .input-long{
    width: 280px;
  }
  .input-middle{
    width: 160px;
  }
  .mr{
    margin-right: 13px;
  }
  .tip{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #C0C4CC;
    line-height: 17px;
    margin-top: 4px;
  }
  .yyzz{
    width: 121px;
    height: 121px;
  }
  .sfz{
    width: 150px;
    height: 87px;
    // margin-right: 20px;
  }
  .imgborder{
    border-radius: 4px;
    border: 1px dashed #FF9100;
    margin-right: 10px;
    height: 97px;
    padding:5px;
  }
}
</style>