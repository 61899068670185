<template>
  <div class="upload">
    <div class="part" v-for="(item, index) of fileList" :key="index">
      <p @click="handleDel(index)" v-if="showClose" class="close pointer">
        <i class="el-icon-plus close-icon"></i>
      </p>
      <el-image
        fit="contain"
        :src="item.path"
        class="part-img"
        :preview-src-list="srcList"
      ></el-image>
    </div>
    <el-upload
      v-if="fileList.length < maxCount"
      action="action"
      :headers="headers"
      :http-request="upload"
      :accept="accept"
    >
      <slot>
        <div class="part add pointer">
          <i class="el-icon-plus icon"></i>
        </div>
      </slot>
    </el-upload>
    <!-- accept="image/jpeg, image/jpg, image/png" -->
    <!-- <el-upload
      ref="ocrref"
      v-show="false"
      :action="ocraction"
      :headers="headers"
      :on-success="handleChange"
      accept="image/jpeg, image/jpg, image/png"
    >
    </el-upload> -->
  </div>
</template>

<script>
import { upload, ocrIdCard, ocrrBusinessLicense } from "@/api/index";
export default {
  name: "Upload",
  model: {
    prop: "picture",
    event: "change",
  },
  props: {
    showClose: {
      type: Boolean,
      default: true,
    },
    accept: {
      type: String,
      default: ".jpg,.jpeg,.png,.jpe", //默认上传类型为img
    },
    maxCount: {
      type: Number,
      default: 1,
    },
    ocr: {
      type: String,
      default: "", // businessLicense idCard
    },
    idCardSide: {
      type: String,
      default: "face", // face/back
    },
  },
  data() {
    return {
      action: `${process.env.VUE_APP_BASE_API}/file/upload`,
      ocraction: "",
      ocractionList: {
        businessLicense: `${process.env.VUE_APP_BASE_API}/file/ocrBusinessLicense`,
        idCard: `${process.env.VUE_APP_BASE_API}/file/ocrIdCard`,
      },
      headers: {
        authorization: `Bearer ${localStorage.getItem("TOKEN") || ""}`,
      },
      loading: false,
      uploadProgress: 0,
      srcList: [],
      fileList: [],
    };
  },
  methods: {
    async upload(params) {
      let param = new FormData();
      param.append("file", params.file);
      let response = await upload(param);
      if (response.code !== 200) {
        return false;
      }
      this.$emit("onChange", response.data);
      if (this.ocr === "idCard") {
        this.ocrIdCard(params.file);
      } else if (this.ocr == "businessLicense") {
        this.ocrrBusinessLicense(params.file);
      }
    },
    async ocrIdCard(file) {
      let param = new FormData();
      param.append("file", file);
      param.append("idCardSide", "face");
      let response = await ocrIdCard(param);
      if (response.code !== 200) {
        return false;
      }
      this.$emit("onOcr", response.data);
    },
    async ocrrBusinessLicense(file) {
      let param = new FormData();
      param.append("file", file);
      let response = await ocrrBusinessLicense(param);
      if (response.code !== 200) {
        return false;
      }
      this.$emit("onOcr", response.data);
    },
    handleDel(index) {
      this.$delete(this.fileList, index);
      this.$emit("onChange", "");
    },
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        if (!isTypeOk) {
          this.$message.error(
            `文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  display: flex;
  /deep/.el-upload-list {
    display: none;
  }
  .close-icon {
    transform: rotate(45deg);
  }
  .part {
    width: 128px;
    height: 128px;
    // background: #F2F6FC;
    border-radius: 4px;
    margin-right: 24px;
    // overflow: hidden;
    position: relative;
    .close {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #eee;
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .part-img {
    width: 100%;
    height: 100%;
  }
  .add {
    border: 1px dashed #dcdfe6;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 24px;
      color: #909399;
    }
  }
}
</style>