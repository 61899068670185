<template>
  <div>
    <el-button
      v-if="btnType === 'text'"
      type="text"
      size="mini"
      @click="handleGetVcode"
      >{{ getText }}</el-button
    >
    <el-button
      v-if="btnType === 'normal'"
      :plain="plain"
      type="primary"
      @click="handleGetVcode"
      >{{ getText }}</el-button
    >
    <el-dialog
      title="验证"
      width="35%"
      append-to-body
      :visible.sync="dialogFormVisible"
    >
      <el-form v-if="dialogFormVisible" :model="form">
        <el-form-item label="验证码:" prop="code">
          <el-input
            clearable
            class="input-small mrl"
            v-model="form.code"
          ></el-input>
          <ImgCode @onChange="imgCodeChange" ref="imgcode" />
          <el-button @click="handleChangeCode" type="text" size="mini">
            换一张
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="verityImgCode">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { VCODE_TIME } from "@/config/index.js";
import { getVCodeNoLogin, getVCodeLogin } from "@/api/index.js";
import { REG_PHONE } from "@/config/regexp.js";
import ImgCode from "./ImgCode.vue";
export default {
  name: "VCode",
  data() {
    return {
      isCountDown: false,
      showTime: VCODE_TIME,
      timeId: 0,
      dialogFormVisible: false,
      form: {
        code: "",
        uuid: "",
      },
    };
  },
  components: {
    ImgCode,
  },
  props: {
    type: {
      type: String,
      default: "REGISTER",
    },
    time: {
      type: Number,
      default: VCODE_TIME,
    },
    text: {
      type: String,
      default: "获取验证码",
    },
    phone: {
      type: String,
      default: "",
    },
    btnType: {
      type: String,
      default: "text",
    },
    plain: {
      type: Boolean,
      default: false,
    },
  },
  onLoad() {
    this.showTime = this.time; // 初始化倒计时时间
  },
  methods: {
    verityImgCode() {
      if (this.form.code.trim() === "") {
        this.$message.error("请填写图形验证码");
        return status;
      }
      this.getVCode();
    },
    handleChangeCode() {
      this.$refs.imgcode.captchaImage();
    },
    imgCodeChange(val) {
      this.form.uuid = val;
    },
    handleGetVcode() {
      let status = true;
      if (this.phone.trim() === "") {
        status = false;
        this.$message.error("请填写手机号");
        return status;
      }

      if (!REG_PHONE.test(this.phone)) {
        status = false;
        this.$message.error("手机号格式不正确");
        return status;
      }
      this.imgCodeShow();
    },
    imgCodeShow() {
      this.dialogFormVisible = true;
    },
    async getVCode() {
      if (!this.isCountDown) {
        let params = {
          phone: this.phone,
          type: this.type,
          code: this.form.code,
          uuid: this.form.uuid,
        };
        let response;
        switch (this.type) {
          case "REGISTER":
          case "FORGET_SECRETKEY":
            response = await getVCodeNoLogin(params);
            break;
          case "CHANGE_SECRETKEY":
          case "APPLY_AUTH":
            response = await getVCodeLogin(params);
            break;
          default:
            response = await getVCodeNoLogin(params);
            break;
        }
        // let response = await getVCodeLogin(params);
        this.dialogFormVisible = false;
        this.form.code = "";
        this.form.uuid = "";
        if (response.code !== 200) {
          this.$message.error(response.msg);
          return false;
        }

        this.countDown();
        this.$message.success("发送成功");
      }
    },
    countDown() {
      this.changeTime();
      this.timeId = setInterval(() => {
        this.changeTime();
      }, 1000);
    },
    changeTime() {
      if (this.showTime > 0) {
        this.isCountDown = true;
        this.showTime -= 1;
      } else {
        clearInterval(this.timeId);
        this.isCountDown = false;
        this.showTime = this.time;
      }
    },
  },
  computed: {
    getText() {
      return this.showTime === this.time
        ? this.text
        : `${this.showTime}s 后获取`;
    },
  },
};
</script>

<style lang="scss" scoped>
._vcode {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff9100;
  line-height: 20px;
  flex-grow: 0;
  flex-shrink: 0;
}
.input-small {
  width: 150px;
}
.mr {
  margin-right: 13px;
}
.mrl {
  margin-right: 24px;
}
</style>