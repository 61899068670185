import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/components/Layout/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Index",
    component: () =>
      import(/* webpackChunkName: "Index" */ "../views/index/index.vue"),
  },
  /* 作品 */
  {
    path: "/works",
    name: "Works",
    component: () =>import(/* webpackChunkName: "works" */ "../views/works/index.vue"),
  },
  {
    path: "/works_detail",
    name: "WorksDetail",
    component: () =>import(/* webpackChunkName: "works" */ "../views/works/detail/index.vue"),
    meta: {
      hideLayout: true
    }
  },
  /* 大师 */
  {
    path: "/master",
    name: "Master",
    component: () =>import(/* webpackChunkName: "works" */ "../views/master/index.vue"),
  },
  /* 产品详情 */
  {
    path: "/product",
    name: "Product",
    component: () =>
      import(/* webpackChunkName: "Product" */ "../views/procuct/index.vue"),
  },

  // 发现
  {
    path: "/discover",
    name: "Discover",
    component: () =>
      import(/* webpackChunkName: "discover" */ "../views/discover/index.vue"),
  },
  // 区块链地址公示
  {
    path: "/block-public",
    name: "BlockPublic",
    component: () =>
      import(
        /* webpackChunkName: "blockPublic" */ "../views/blockPublic/index.vue"
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE,
  routes,
});

export default router;
