<template>
  <div class="add-wallet">
    <el-form ref="form" :model="form" :rules="rules" label-width="130px">
      <el-form-item label="区块链地址名称:" prop="name">
        <el-input
          v-model="form.name"
          maxlength="12"
          placeholder="请输入1-12位区块链地址名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码:" prop="password">
        <el-input
          type="password"
          v-model="form.password"
          placeholder="请输入密码"
          auto-complete="new-password"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码:" prop="twicePassword">
        <el-input
          type="password"
          v-model="form.twicePassword"
          placeholder="请输入密码"
          auto-complete="new-password"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码提示:" prop="remind">
        <el-input v-model="form.remind" placeholder="请输入密码提示"></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-btn">
      <el-button @click="$emit('onCancel')">取消</el-button>
      <el-button type="primary" @click="handleCreate">创建并添加</el-button>
    </div>
  </div>
</template>

<script>
import { addWallet } from "@/api/index";
import { REG_PASSWORD } from "@/config/regexp.js";
export default {
  name: "add-wallet",
  data() {
    var checkPassword = (rule, value, callback) => {
      setTimeout(() => {
        if (value !== this.form.password) {
          callback(new Error("两次输入的密码不一样"));
        } else {
          callback();
        }
      }, 10);
    };
    return {
      form: {
        name: "",
        password: "",
        remind: "",
        twicePassword: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入区块链地址名称", trigger: "blur" },
          {
            min: 1,
            max: 12,
            message: "长度在 1 到 12 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { pattern: REG_PASSWORD, message: '密码长度最少8位,且至少包含数字/字母，不包含空格和汉字' }
        ],
        twicePassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkPassword, trigger: "blur" },
        ],
        remind: [
          { required: true, message: "请输入密码提示", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleCreate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.addWallet();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    clearForm(data) {
      for (const key in data) {
        data[key] = "";
      }
    },
    async addWallet() {
      let params = {
        name: this.form.name,
        password: this.form.password,
        remind: this.form.remind,
        twicePassword: this.form.twicePassword,
      };
      let response = await addWallet(params);
      if (response.code !== 200) {
        this.$message.error({ message: response.msg, customClass: "_zindex" });
        return false;
      }
      let walletAddress = this.$store.state.userInfo.walletAddress;
      if (!walletAddress) {
        window.location.reload();
      }
      this.$message.success({ message: response.msg, customClass: "_zindex" });
      this.clearForm(this.form);
      this.$emit("onChange");
    },
  },
};
</script>
