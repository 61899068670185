<template>
  <div class="user">
    <el-popover popper-class="popper-class" placement="top" width="180" trigger="hover">
      <div slot="reference" class="avatar pointer">
        <el-avatar v-if="getUserInfo.avatar" :src="getUserInfo.avatar" size="small"></el-avatar>
        <img v-if="!getUserInfo.avatar" class="avatar-img" src="../../assets/images/avatar.png" alt="">
      </div>
      <UserInfoPopup />
    </el-popover>
    
    <div class="icon pointer" @click="toggleWallet">
      <img src="@/assets/images/wallet-a.png" alt="资产">
    </div>
  </div>
</template>

<script>
import UserInfoPopup from './UserInfoPopup.vue'
export default {
  name: "User",
  data() {
    return {
      visible: false
    }
  },
  components: {
    UserInfoPopup,
  },
  methods: {
    toggleWallet() {
      this.$store.dispatch('setWallet', !this.$store.state.showWallet); 
    },
    
  },
  computed: {
    getUserInfo() {
      return this.$store.state.userInfo;
    },
  }
}
</script>

<style lang="scss" scoped>
.user{
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.icon{
  width: 36px;
  height: 36px;
  transform: translateY(-3px);
  margin-left: 30px;
  img{
    width: 100%;
  }
}
.avatar{
  // width: 40px;
  // background: #f00;
  // height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid transparent;
  transition: all 0.3s;
  &:hover{
    border-bottom: 4px solid $pcolor;
  }
}
.avatar-img{
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

</style>