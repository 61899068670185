<template>
  <div class="loginform">
    <LoginTitle title="忘记密码"></LoginTitle>
    <template v-if="step === 0">
      <Input
        iconType="phone"
        :maxlength="11"
        v-model="phone"
        placeholder="请输入手机号"
      ></Input>
      <Input
        iconType="code"
        placeholder="请输入验证码"
        v-model="auth_code"
        :maxlength="6"
      >
        <VCode type="FORGET_SECRETKEY" slot="end" :phone="phone" />
      </Input>
      <el-button
        @click="nextFn"
        style="width: 100%; margin-top: 70px"
        type="primary"
        round
      >
        下一步
      </el-button>
    </template>
    <template v-if="step === 1">
      <Input
        iconType="pwd"
        v-model="password"
        placeholder="请输入新密码"
        type="password"
      ></Input>
      <Input
        iconType="pwd"
        v-model="confirm_pwd"
        placeholder="请确认新密码"
        type="password"
      ></Input>
      <el-button
        @click="confirmFn"
        style="width: 100%; margin-top: 70px"
        type="primary"
        round
      >
        确 认
      </el-button>
    </template>
  </div>
</template>

<script>
import { checkVCode, forgetPassword } from "@/api/index.js";
import LoginTitle from "./LoginTitle.vue";
import Input from "./Input.vue";
import VCode from "@/components/Vcode/index";
import { REG_PHONE, REG_PASSWORD} from "@/config/regexp.js";

export default {
  name: "LoginForm",
  components: {
    LoginTitle,
    Input,
    VCode,
  },
  data() {
    return {
      phone: "",
      step: 0,
      password: "",
      confirm_pwd: "",
      auth_code: "",
    };
  },
  methods: {
    async confirmFn() {
      let status = true;
      if (this.password.trim() === "") {
        status = false;
        this.$message.warning("请输入密码");
        return status;
      }
      if(!REG_PASSWORD.test(this.password)) {
        status = false;
        this.$message.warning("密码长度最少8位,且至少包含数字、字母、特殊字符两种及以上，不包含空格和汉字");
        return status
      }
      if (this.password.trim() !== this.confirm_pwd.trim()) {
        status = false;
        this.$message({
          message: "两次输入密码不一致",
          type: "warning",
        });
        return status;
      }
      let params = {
        password: this.password,
        phonenumber: this.phone,
        twicePassword: this.confirm_pwd,
        // "verifyCode": this.auth_code
      };
      let data = await forgetPassword(params);
      if (data.code !== 200) {
        this.$message({
          message: data.msg,
          type: "error",
        });
        return false;
      }
      this.toReg();
    },
    nextFn() {
      let status = true;
      if (this.phone.trim() === "") {
        status = false;
        this.$message.warning({ message: "请填写手机号" });
        return status;
      }

      if (!REG_PHONE.test(this.phone)) {
        status = false;
        this.$message.warning("手机号格式不正确");
        return status;
      }
      if (this.auth_code.trim() === "" || this.auth_code.trim().length < 6) {
        status = false;
        this.$message.warning("请填写验证码");
        return status;
      }

      if (!/^[0-9]*$/.test(this.auth_code)) {
        status = false;
        this.$message.warning("验证码格式不正确");
        return status;
      }
      this.verityCode();
    },
    async verityCode() {
      let params = {
        phonenumber: this.phone,
        verifyCode: this.auth_code,
      };
      let data = await checkVCode(params);
      if (data.code !== 200) {
        this.$message({
          message: data.msg,
          type: "error",
        });
        return false;
      }
      this.step = 1;
    },
    toReg() {
      this.$store.dispatch("setLoginDialog", { isShow: true, type: "login" });
    },
  },
};
</script>