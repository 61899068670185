<template>
  <div class="wallet-list">
    <div class="top">
      <div @click="handleSelect(item)" class="list-item pointer" :class="{'actived' : getUsingNumber === item.walletNumber }" v-for="(item, index) of walletList" :key="index">
        <div class="title">
          {{item.name}}
          <div v-if="item.publicShow !== 1" class="tag">未公示</div>
          <div v-if="item.publicShow === 1" class="tag tag-a">已公示</div>
        </div>
        <p class="code">{{item.address}}</p>
      </div>
    </div>
    <div class="bottom">
      <el-button @click="realnameVisible = true" type="primary" class="addWallet" size="mini" plain round>添加区块链地址</el-button>
    </div>
    <!-- 弹窗 -->
    <el-dialog append-to-body title="添加区块链地址" :visible.sync="realnameVisible" width="500px">
      <Add @onChange="handleAdd" @onCancel="realnameVisible = false"/>
    </el-dialog>
  </div>
</template>

<script>
import { getWalletList, switchWallet } from '@/api'
import Add from './Add.vue'
export default {
  name: "wallet-list",
  data() {
    return {
      realnameVisible: false,
      walletList: [],
    }
  },
  components: {
    Add,
  },
  mounted() {
    this.getWalletList();
  },
  methods: {
    // 添加钱包之后的回调
    handleAdd() {
      this.realnameVisible = false;
      this.getWalletList();
    },
    async getWalletList() {
      let response = await getWalletList();
      if(response.code !== 200) {
        this.$message.error({message:response.msg, customClass: '_zindex'});
        return false;
      }
      this.walletList = response.data;
      // this.$message.success({message:response.msg, customClass: '_zindex'});
    },
    
    async handleSelect(item) {
      let walletNumber = item.walletNumber;
      let response = await switchWallet({walletNumber: walletNumber});
      if(response.code !== 200) {
        this.$message.error({message:response.msg, customClass: '_zindex'});
        return false
      }
      this.$message.success({message:response.msg, customClass: '_zindex'});
      this.$store.dispatch('refreshUserInfo');
      this.$emit('onChange',walletNumber)
    }
  },
  computed: {
    getUsingNumber() {
      let usingNumber = this.$store.state.userInfo.walletNumber || '';
      return usingNumber;
    },
  }
}
</script>

<style lang="scss" scoped>
.wallet-list{
  height: 430px;
  .top{
    height: 368px;
    overflow: auto;
    padding: 15px;
    .list-item{
      width: 100%;
      padding: 10px;
      // height: 69px;
      background: #F5F7FA;
      border-radius: 10px;
      transition: all 0.3s;
      margin-bottom: 15px;
      &:hover{
        background: #FFF7E6;
      }
      .title{
        height: 25px;
        line-height: 25px;
        max-width: 250px;
        font-size: 16px;
        font-family:  PingFang SC;
        font-weight: 500;
        color: #262626;
        display: flex;
        margin-bottom: 3px;
        display: flex;
        align-items: center;
      }
      .code{
        // height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
        line-height: 20px;
      }
    }
    .actived{
      border: 2px solid #FF9100;
      background: #FFF7E6;
    }
  }
  .bottom{
    height: 62px;
    background: #FFFFFF;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 10px 10px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    // justify-content: center;
    .addWallet{
      width: 100%;
      height: 32px;
    }
  }
}
.tag{
  padding: 0 8px;
  height: 18px;
  line-height: 18px;
  border-radius: 9px;
  border: 1px solid #909399;
  font-size: 10px;
  font-family:  PingFang SC;
  font-weight: 500;
  color: #909399;
  margin-left: 10px;
}
.tag-a{
  color: $pcolor;
  border-color: $pcolor;
}
</style>