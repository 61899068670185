<template>
  <div id="app">
    <Nav v-if="!hideLayout" :isOpacity="isOpacity" />
    <div class="_page" :class="{ active: isProduct }">
      <router-view  />
    </div>
    <Footer v-if="!hideFooter" />
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";
import RealNameAuth from "@/components/Auth/RealNameAuth.vue";
import RoleAuth from "@/components/Auth/RoleAuth.vue";
import Login from "@/components/Login/index.vue";
import Wallet from "@/components/Wallet/index.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
    RealNameAuth,
    RoleAuth,
    Login,
    Wallet,
  },
  data() {
    return {
      realnameVisible: false,
      isOpacity: false,
      hideFooter: false,
      // navMode: "dark",
      // roleVisible: false,
      // showWallet: false,
    };
  },
  mounted() {
    // this.getUserInfo();

    // this.$store.dispatch("setRoleAuth", true);
  },
  methods: {
    getUserInfo() {
      const TOKEN = this.$store.state.TOKEN || null;
      if (TOKEN) {
        this.$store.dispatch("refreshUserInfo");
      }
    },
    closeWallet() {
      this.$store.dispatch("setWallet", false);
    },
    closeRole() {
      this.$store.dispatch("setRoleAuth", false);
    },
    closeRealName() {
      this.$store.dispatch("setRealNameAuth", false);
    },
  },
  computed: {
    showWallet() {
      return this.$store.state.showWallet;
    },
    roleVisible() {
      return this.$store.state.showRollAuth;
    },
    realNameVisible() {
      return this.$store.state.showRealNameAuth;
    },
    isProduct() {
      return this.$route.name === "Product";
    },
    hideLayout() {
      return this.$route.meta && this.$route.meta.hideLayout;
    },
  },
  watch: {
    "$route.name"(val) {
      if (val === "Index") {
        this.isOpacity = true;
      } else {
        this.isOpacity = false;
      }
      if(val === 'WorksDetail' || val === 'Works' || val === 'Master') {
        this.hideFooter = true;
      } else {
        this.hideFooter = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("./assets/style/base.scss");
#app {
  background: #f5f7fa;
  .el-drawer__wrapper {
    z-index: 101 !important;
  }
}

.el-popover {
  // z-index: 1000!important;
  padding: 0 !important;
}
._zindex {
  z-index: 3000;
}
._page {
  // min-height: 100vh;
  // background-color: #f5f7fa;
  background: #fff;
  &.active {
    background-color: #fff;
  }
}
/deep/.walleteldrawer{
  top: 60px!important;
  bottom: 0!important;
}
/deep/.el-drawer__body{
  position: relative;
}
// .eldrawer{
//   z-index: 9999!important;
// }


// .el-dialog{
//   display: flex;
//   flex-direction: column;
//   margin: 0!important;
// }
// .el-dialog__wrapper{
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
/deep/.el-dialog__wrapper{
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // overflow: auto;

}
/deep/.el-dialog{
  // margin: auto!important;
  // display: flex;
  // flex-direction: column;
  // margin:0 !important;
  // position:absolute;
  // top:50%;
  // left:50%;
  // transform:translate(-50%,-50%);
}
</style>
