// 验证码类型
export const REGISTER = "REGISTER";
export const CHANGE_SECRETKEY = "CHANGE_SECRETKEY";
export const APPLY_AUTH = "APPLY_AUTH";
export const FORGET_SECRETKEY = "FORGET_SECRETKEY";
export const VCODE_TIME = 30; // 获取验证码时间 60秒

// export const BASEURL = 'http://192.168.50.111:8080'; // 罗义军本地
// export const BASEURL = "http://192.168.50.143:8080"; // 王栋
// export const BASEURL = 'https://test.yibee.com/api/traceart-admin'; // 开发环境
export const BASEURL = 'https://nftdev.thetraceart.com/api/traceart-admin'; // 开发环境
export const TOKEN_AUTH = [
  "/front/login/checkVerifyCode",
  "/front/login/forgetPassword",
  "/front/login/login?userType=01",
  "/front/login/register",
  "/front/login/sendSmsVerifyCode",
  "/front/login/productList",
  "/front/login/productInfo",
  "/captchaImage",
  "/front/product/getThreeModel"
];
export const NO_AUTH = [
  "Index", 
  "Discover", 
  "Threed",
  "Agreement", 
  "AgreementH5", 
  "Works",
  "BlockPublic",
  "WorksDetail",
  "Master",
];
export const ROLE_ENUM = {
  // 0: '普通用户',
  1: "设计师",
  2: "生产方",
  3: "发行方",
  4: "版权方",
};

export const AUTH_TEMPLATE =
  "https://eb-pub-1.oss.aliyuncs.com/2021/08/23/358e9a75-8c31-4a88-abfb-4538d5f64ce8.docx?Expires=3261431955&OSSAccessKeyId=LTAIMa6KtMvqaR6H&Signature=g0X1VoLYOFH9zL5c4%2FkrEU8w5Bk%3D";

export const ISSUE_TYPE = {
  1: "自营发行",
};

export const TIPS = {
  repeatAuth: '已提交过实名认证申请，可以在【认证记录】中查看申请详情',
}