<template>
  <div class="login-input">
    <div class="icon">
      <img
        v-if="iconType === 'code'"
        src="@/assets/images/icon_code.png"
        alt=""
      />
      <img
        v-if="iconType === 'phone'"
        src="@/assets/images/icon_phone.png"
        alt=""
      />
      <img
        v-if="iconType === 'pwd'"
        src="@/assets/images/icon_pwd.png"
        alt=""
      />
    </div>
    <input
      :value="val"
      :maxlength="maxlength"
      class="input-area"
      @change="inputOnChange"
      :placeholder="placeholder"
      autoComplete="new-password"
      :type="getType"
    />
    <slot name="end"></slot>
    <template v-if="showToggle">
      <img @click="showPwd = !showPwd" v-if="!showPwd" class="eye-icon" src="@/assets/images/eye-close.png" alt="">
      <img @click="showPwd = !showPwd" v-if='showPwd' class="eye-icon" src="@/assets/images/eye-open.png" alt="">
    </template>
  </div>
</template>

<script>
export default {
  name: "Input",
  model: {
    prop: "val",
    event: "onChange",
  },
  data() {
    return {
      showPwd: false
    }
  },
  props: {
    val: {
      default: "",
    },
    iconType: {
      type: String,
      default: "phone",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: -1,
    },
    showToggle: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    inputOnChange(val) {
      this.$emit("onChange", val.target.value);
    },
  },
  computed: {
    getType() {
      return this.type === 'password' && !this.showPwd ? 'password' : 'text'
    }
  }
};
</script>

<style lang="scss" scoped>
.login-input {
  width: 100%;
  height: 46px;
  background: #f2f6fc;
  border-radius: 23px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-width: 100%;
      height: 100%;
    }
  }
  .input-area {
    width: 100%;
    height: 40px;
    line-height: 40px;
    outline: none;
    border: none;
    background: none;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .eye-icon{
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>