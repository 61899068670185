// import Vue from 'vue'
import axios from "axios";
import { MessageBox } from "element-ui";

import store from "@/store";
import { TOKEN_AUTH } from "@/config/index.js";
import router from "@/router/index";
let isMessage = false;
// BASEURL
let noAuth = function(url) {
  let status = false;
  TOKEN_AUTH.forEach((item) => {
    if (url.indexOf(item) !== -1) {
      status = true;
    }
  });
  return status;
};

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000,
});

service.interceptors.request.use(
  (config) => {
/*    let token = localStorage.getItem("TOKEN") || "";
    if(token && !noAuth(config.url)) {
      config.headers.authorization = `Bearer ${token}`;
    }*/
    // let JSESSIONID = uni.getStorageSync('JSESSIONID') || '';
    // if(JSESSIONID) {
    //   config.headers['Set-Cookie'] = JSESSIONID;
    // }
    config.headers["Content-Type"] = "application/json";
    config.headers["channel"] = "PC";
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    let code = res.data.code;
    if (code === 401) {
      router.replace({ name: "Index" });
      if (!isMessage) {
        isMessage = true;
        MessageBox.confirm("登录状态已过期，需要重新登录", "系统提示", {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            store.dispatch("logout");
            isMessage = false;
            window.location.reload();
          })
          .catch(() => {
            store.dispatch("logout");
            isMessage = false;
            window.location.reload();
          });
      }
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log("err" + error);
    return Promise.reject(error);
  }
);

export default service;
