<template>
  <div class="user-info">
    <div v-ellipsis class="name" :title="getUserInfo.nickName">
      {{ getUserInfo.nickName }}
    </div>
    <div class="tag-list">
      <Tag class="mr" type="error" v-if="getUserInfo.authStatus !== 1"
        >未实名</Tag
      >
      <Tag class="mr" type="normal" v-if="getUserInfo.authStatus === 1"
        >已实名</Tag
      >
      <!-- 角色 -->
      <Tag
        class="mr"
        type="normal"
        v-for="item of getUserInfo.roleName"
        :key="item.roleId"
        >{{ item.roleName }}</Tag
      >
    </div>
    <div class="line"></div>
    <ul class="fun-list">
      <li class="fun-item" @click="toMinePage">个人中心</li>
      <li
        class="fun-item"
        v-if="getUserInfo.authStatus !== 1"
        @click="handleToAuth"
      >
        申请实名认证
      </li>
      <li class="fun-item" @click="logout">退出登录</li>
    </ul>
  </div>
</template>

<script>
import Tag from "@/components/Tag.vue";
import { TIPS } from '@/config/index.js'
const repeatAuth = TIPS.repeatAuth;
export default {
  name: "UserInfo",
  components: {
    Tag,
  },
  methods: {
    toMinePage() {
      this.$router.push({
        name: "Mine",
      });
      // this.$store.dispatch('setLoginDialog', {isShow: true, type: 'login'});
    },
    logout() {
      this.$store.dispatch("logout");
      this.$message.success("退出登录成功");
      this.$router.replace({ name: "Index" });
    },
    handleToAuth() {
      if (this.getUserInfo.authApplyType === 1) {
        this.$alert(repeatAuth, "提示", {
          confirmButtonText: "确定",
        })
          .then(() => {})
          .catch(() => {});
        return false;
      }
      this.$store.dispatch("setRealNameAuth", true);
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.state.userInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info {
  padding: 16px 15px 15px;
  .name {
    height: 30px;
    font-size: 22px;
    font-weight: 500;
    color: #262626;
    line-height: 30px;
    margin-bottom: 5px;
  }
  .tag-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    // .tag-item{
    //   padding: 0 8px;
    //   height: 20px;
    //   line-height: 20px;
    //   font-size: 12px;
    //   border-radius: 11px;
    //   margin-right: 8px;
    //   margin-bottom: 17px;
    // }
    // .red-tag{
    //   border: 1px solid #FFCCC7;
    //   background: #FFF1F0;
    //   color: #FF4D4F;
    // }
    // .blue-tag{
    //   border: 1px solid #8FCEFF;
    //   background: #DCF0FF;
    //   color: #008DFF;
    // }
  }
}
.fun-list {
  padding: 16px 0 0 9px;
  .fun-item {
    min-height: 22px;
    line-height: 22px;
    font-size: 16px;
    color: #262626;
    font-weight: 400;
    margin-bottom: 16px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      color: $pcolor;
    }
    &:last-child{
      margin-bottom: 9px;
    }
  }
}
.line {
  width: 100%;
  height: 2px;
  background: #f2f6fc;
}
.mr {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>