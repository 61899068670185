<template>
  <div class="loginform">
    <LoginTitle title="注册账号">
      <span @click="toLogin">已有账号，立即登录</span>
    </LoginTitle>
    <Input
      iconType="phone"
      :maxlength="11"
      v-model="phone"
      placeholder="请输入手机号"
    ></Input>
    <Input
      iconType="code"
      placeholder="请输入验证码"
      v-model="auth_code"
      :maxlength="6"
    >
      <VCode slot="end" :phone="phone" />
    </Input>
    <Input
      iconType="pwd"
      placeholder="请输入密码"
      v-model="password"
      type="password"
    ></Input>
    <Input
      iconType="pwd"
      style="margin-bottom: 5px"
      placeholder="请确认密码"
      type="password"
      v-model="confirm_pwd"
    ></Input>
    <div class="tip" @click="isCreate = !isCreate">
      <i class="radioicon" v-show="!isCreate"></i>
      <i class="icon el-icon-success actived-icon" v-show="isCreate"></i>
      注册后自动创建一个默认区块链地址
    </div>
    <el-button style="width: 100%" @click="registerFn" type="primary" round
      >注 册</el-button
    >
    <div class="prol" @click="isAgreen = !isAgreen">
      <i class="radioicon" v-show="!isAgreen"></i>
      <i class="icon el-icon-success actived-icon" v-show="isAgreen"></i>
      <span>获取验证码代表已阅读并同意</span>
      <span class="pcolor" @click.stop="agreementChick('fwxy')">《服务协议》</span>、
      <span class="pcolor" @click.stop="agreementChick('ystk')">《隐私条款》</span>
    </div>
  </div>
</template>

<script>
import LoginTitle from "./LoginTitle.vue";
import Input from "./Input.vue";
import VCode from "@/components/Vcode/index";
import { REG_PHONE, REG_PASSWORD } from "@/config/regexp.js";
import { register } from "@/api";
export default {
  name: "RegisterForm",
  components: {
    LoginTitle,
    Input,
    VCode,
  },
  data() {
    return {
      phone: "",
      auth_code: "",
      password: "",
      confirm_pwd: "",
      isCreate: true,
      isAgreen: true,
    };
  },
  methods: {
    agreementChick(type="fwxy") {
      let routeData = this.$router.resolve({
        name: "Agreement",
        query: {
          type: type,
        },
      });
      window.open(routeData.href, "_blank");
    },
    registerFn() {
      let status = true;
      if (this.phone.trim() === "") {
        status = false;
        this.$message.warning("请填写手机号");
        return status;
      }

      if (!REG_PHONE.test(this.phone)) {
        status = false;
        this.$message.warning("手机号格式不正确");
        return status;
      }
      if (this.auth_code.trim() === "" || this.auth_code.trim().length < 6) {
        status = false;
        this.$message.warning("请填写验证码");
        return status;
      }

      if (!/^[0-9]*$/.test(this.auth_code)) {
        status = false;
        this.$message.warning("验证码格式不正确");
        return status;
      }
      if (this.password.trim() === "") {
        status = false;
        this.$message.warning("请输入密码");
        return status;
      }
      if(!REG_PASSWORD.test(this.password)) {
        status = false;
        this.$message.warning("密码长度最少8位,且至少包含数字、字母、特殊字符两种及以上，不包含空格和汉字");
        return status
      }
      if (this.password.trim() !== this.confirm_pwd.trim()) {
        status = false;
        this.$message({
          message: "两次输入密码不一致",
          type: "warning",
        });
        return status;
      }
      if (!this.isAgreen) {
        status = false;
        this.$message({
          message: "请先阅读并同意相关协议",
          type: "warning",
        });
        return status;
      }
      this.registers();
    },
    async registers() {
      let params = {
        isAutoCreateWallet: this.isCreate,
        password: this.password,
        phonenumber: this.phone,
        twicePassword: this.confirm_pwd,
        verifyCode: this.auth_code,
      };
      let response = await register(params);
      if (response.code !== 200) {
        this.$message({
          message: response.msg,
          type: "error",
        });
      } else {
        this.$message({
          message: "注册成功",
          type: "success",
        });
        setTimeout(() => {
          this.$store.dispatch("setLoginDialog", {
            isShow: true,
            type: "login",
          });
        }, 1000);
      }
    },
    toLogin() {
      this.$store.dispatch("setLoginDialog", { isShow: true, type: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 40px;
}
.icon {
  font-size: 18px;
  margin-right: 5px;
}
.actived-icon {
  color: $pcolor;
}
.prol {
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 23px;
  cursor: pointer;
}
.radioicon {
  width: 16px;
  height: 16px;
  border: 2px solid #000;
  border-radius: 50%;
  margin-right: 7px;
}
.radio-active {
  border-color: $pcolor;
}
.top,
.prol {
  user-select: none;
}
.pcolor {
  color: $pcolor;
}
</style>