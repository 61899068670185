<template>
  <div class="img-code" @click="captchaImage">
    <img :src="imgBase64">
  </div>
</template>

<script>
import { captchaImage } from '@/api/index'
export default {
  name: "ImgCode",
  data() {
    return {
      imgBase64: '',
    }
  },
  mounted() {
    this.captchaImage();
  },
  methods: {
    async captchaImage() {
      let response = await captchaImage();
      if(response.code !== 200) {
        this.$message.error(response.msg);
        return false
      }
      this.imgBase64 = `data:image/png;base64,${response.data.img}`;
      this.$emit('onChange', response.data.uuid)
    }
  }
}
</script>

<style lang="scss" scoped>
.img-code{
  width: 88px;
  height: 40px;
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  background: #eee;
  display: inline-block;
  vertical-align: middle;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>